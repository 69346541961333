package com.ilussobsa.views

import com.ilussobsa.*
import com.ilussobsa.Strings
import com.ilussobsa.sdk.*
import com.ilussobsa.utils.*
import com.ilussobsa.views.dialogs.ConfirmUpdateListing
import com.ilussobsa.views.dialogs.ExpandedImage
import com.ilussobsa.views.dialogs.GenericConfirmationDialog
import com.ilussobsa.views.dialogs.SendEmailDialog
import com.lightningkite.UUID
import com.lightningkite.kiteui.locale.renderDateToString
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.navigation.screenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.ViewWriter
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.icon
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import com.lightningkite.uuid
import kotlin.math.roundToInt
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock.System.now
import kotlin.math.max
import kotlin.math.min

sealed class GalleryMedia {
    abstract val type: String
    abstract val location: String
}

class GalleryVehicleMedia(val media: VehicleMedia) : GalleryMedia() {
    override val type: String by media::type
    override val location: String by media.file::location
}

class GalleryDamagePhoto(val damage: Damage) : GalleryMedia() {
    override val type: String get() = ""
    override val location: String by damage.image::location
}

fun ViewWriter.vehicleInfo(selectedLot: UUID, immutable: Boolean = false, goto: suspend (vehicleId: UUID?) -> Unit) {
    val selected = shared { currentSessionNullable.awaitNotNull().vehicles.get(selectedLot)() }
    val vehicle = selected

    col {
        rowCollapsingToColumn(95.rem) {
            atTopCenter - sizeConstraints(width = 35.rem) - col {
//                val selectedImage = Property<Pair<ServerFile?, String>>(null to "")
//                val imageList = Property(listOf<String>())
                val scrollIndex = Property(0)
                val imageList = shared {
                    val vehicle = vehicle.awaitNotNull()
                    vehicle.media.takeIf { it.isNotEmpty() }?.let {
                        it.map { media -> GalleryVehicleMedia(media) } +
                        (vehicle.damage ?: listOf()).map { damage -> GalleryDamagePhoto(damage) }
                    } ?: listOf()
                }
                val selectedImage = LazyProperty { imageList.awaitNotNull().first() }
                sizeConstraints(
                    height = (35.0 * 2 / 3).rem.coerceAtMost(
                        WindowInfo.value.width.minus(defaultAppTheme.spacing * 2).times(2).div(3)
                    )
                ) - image {
                    exists = false
                    ::exists {
                        !selectedImage().type.contains("video")
                    }
                    ::source {
                        selectedImage().location.let(::ImageRemote)
                    }
                    this.description = ""
                    scaleType = ImageScaleType.Crop
                }
                sizeConstraints(
                    height = (35.0 * 2 / 3).rem.coerceAtMost(
                        WindowInfo.value.width.minus(defaultAppTheme.spacing * 2).times(2).div(3)
                    )
                ) - video {
                    exists = false
                    ::exists {
                        selectedImage().type.contains("video")
                    }
                    ::source {
                        selectedImage().location.let(::VideoRemote)
                    }
                    showControls = true
                    loop = false
                    scaleType = ImageScaleType.Crop
                    launch {
                        volume set 0f
                        playing set false
                    }
                }
                stack {
                    scrollsHorizontally - row {
                        forEachUpdating(
                            items = shared { imageList().withIndex().toList() }
                        ) {
                            stack {
                                reactiveScope {
                                    if (scrollIndex() == it().index) scrollIntoView(
                                        Align.Center,
                                        null,
                                        true
                                    )
                                }
                                unpadded - button {
                                    sizedBox(SizeConstraints(height = 5.rem, width = 8.rem)) - image {
                                        ::source {
                                            if (it().value.type.contains("video")) Icon.roundPlay.toImageSource(Color.gray)
                                            else it().value.location.let(::ImageRemote)
                                        }
                                        this.description = ""
                                        ::scaleType { if (it().value.type.contains("video")) ImageScaleType.Fit else ImageScaleType.Crop }
                                    }
                                    onClick {
                                        selectedImage.value = it().value
                                        scrollIndex set it().index
                                    }
                                }
                            }
                        }
                    }
                    // Previous Button
                    gravity(Align.Start, Align.Center) - sizeConstraints(
                        width = 2.rem,
                        height = 5.rem
                    ) - button {
                        spacing = 0.dp
                        ::exists { (vehicle()?.media?.size ?: 0) + (vehicle()?.damage?.size ?: 0) > 4 }
                        centered - icon(Icon.chevronLeft.copy(height = 3.rem), "Preview Image")
                        onClick {
                            scrollIndex set max(scrollIndex() - 3, 0)
                        }
                    }
                    // Next Button
                    gravity(Align.End, Align.Center) - sizeConstraints(width = 2.rem, height = 5.rem) - button {
                        spacing = 0.dp
                        ::exists { (vehicle()?.media?.size ?: 0) + (vehicle()?.damage?.size ?: 0) > 4 }
                        centered - icon(Icon.chevronRight.copy(height = 3.rem), "Next Image")
                        onClick {
                            scrollIndex set min(scrollIndex() + 3, imageList().size - 1)
                        }
                    }
                }
            }
            expanding - vehicleSideCard(
                selected,
                selectedLot,
                immutable,
                goto,
            )
        }
        vehicleWinner(selected)
        rowCollapsingToColumn(50.rem) {
            expanding - vehicleConsiderations(vehicle)
            expanding - vehicleDamage(vehicle)
        }
        vehicleBuyer(vehicle)
        vehiclePreviousRuns(vehicle)
    }
}

fun ViewWriter.vehicleSideCard(
    selected: Readable<Vehicle?>,
    selectedLot: UUID,
    immutable: Boolean,
    goto: suspend (vehicleId: UUID?) -> Unit,
) {
    val vehicle = selected
    val isBuyer = shared { selected()?.completion?.winner == currentUser()?._id }
    val reserve = Property<Int?>(null)
    val proxyBid = Property<Int?>(null)
    card - col {
        launch {
            reserve.set(vehicle.awaitNotNull().reserve)
        }
        h2 {
            ::content {
                selected()?.statusDescription() ?: ""
            }
        }
        if (immutable) {
            row {
                ::exists{ isSeller() }
                gravity(Align.Start, Align.Center) - bold - text(Strings.reserve1)
                expanding - text {
                    ::exists { selected()?.completion != null }
                    ::content{
                        selected()?.reserve?.renderPriceInDollars() ?: "-"
                    }
                }
            }
        } else {
            row {
                ::exists{ isSeller() }
                gravity(Align.Start, Align.Center) - bold - text(Strings.reserve1)
                priceField(reserve) { field ->
                    ::exists { selected()?.completion == null }
                    centered - compact - unpadded - button {
                        spacing = 0.px
                        ::enabled { reserve() != selected()?.reserve }
                        icon(Icon.done, Strings.save)
                        onClickAssociatedField(field) {
                            if (reserve.await() !== null) {
                                vehicle.modify(modification {
                                    it.reserve assign reserve.value
                                })
                            } else {
                                alert(Strings.error, Strings.thisFieldIsRequiredPleaseProvideAResponse)
                            }
                        }
                    }
                }
                expanding - text {
                    ::exists { selected()?.completion != null }
                    ::content {
                        selected()?.reserve?.renderPriceInDollars() ?: "Not set"
                    }
                }
            }
            vehicleActionButtons(selected, selectedLot, goto, isSeller)
            important - row {
                exists = false
                ::exists {
                    isBuyer() && vehicle()?.let {
                        it.cancelled == null && it.archived == null && it.rerunStarted == null && it.completion?.sold == false
                    } ?: false
                }
                expanding - text(Strings.awaitingReview)
            }
            row {
                val existingProxyBid =
                    vehicleRelationshipUpsertProp(selectedLot, VehicleRelationship.path.autobid)
                launch {
                    proxyBid set existingProxyBid()
                }
                ::exists { !isSeller() }
                gravity(Align.Start, Align.Center) - bold - text(Strings.proxyBid)
                priceField(proxyBid) { field ->
                    ::exists { selected()?.completion == null }
                    centered - compact - unpadded - button {
                        spacing = 0.px
                        ::exists { selected()?.liveAt == null }
                        ::enabled { proxyBid() != existingProxyBid() }
                        text {
                            ::content { if (proxyBid() != existingProxyBid()) Strings.save else Strings.saved }
                        }
                        onClickAssociatedField(field) {
                            vehicleRelationshipUpsert(selectedLot, modification {
                                it.autobid assign proxyBid.value
                                it.autobidBeaten assign null
                            })
                        }
                    }
                }
                expanding - text {
                    ::exists { selected()?.completion != null }
                    ::content{
                        existingProxyBid()?.renderPriceInDollars() ?: "-"
                    }
                }
            }
        }
        important - row {
            ::exists{ selected()?.certifiedPreOwned != null }
            centered - sizeConstraints(2.rem, 2.rem) - icon {
                source = Icon.certification
            }
            compact - col {
                text {
                    ::content{ Strings.thisVehicleAppearsToMeetTheMinimumCriteria }
                }
                bold - text {
                    ::content{ selected()?.certifiedPreOwned ?: "-" }
                }
            }

        }
        compact - rowCollapsingToColumn(30.rem, 95.rem, 110.rem) {
            expanding - col {
                ::exists { selected.awaitNotNull().exteriorColor?.isNotEmpty() == true || selected.awaitNotNull().interiorColor?.isNotEmpty() == true || selected.awaitNotNull().trim?.isNotEmpty() == true }
                row {
                    ::exists { selected.awaitNotNull().exteriorColor?.isNotEmpty() == true }
                    bold - text(Strings.exterior2)
                    centered - text { ::content{ selected.awaitNotNull().exteriorColor.toString() } }
                }
                row {
                    ::exists { selected.awaitNotNull().interiorColor?.isNotEmpty() == true }
                    bold - text(Strings.interior2)
                    centered - text {
                        ::content{ selected.awaitNotNull().interiorColor.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().trim?.isNotEmpty() == true }
                    bold - text(Strings.trim1)
                    centered - text {
                        ::content{ selected.awaitNotNull().trim.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().keys !== null }
                    bold - text(Strings.keys2)
                    centered - text {
                        ::content{ selected.awaitNotNull().keys?.text ?: "-" }
                    }
                }
//                row {
//                    ::exists { selected.awaitNotNull().reconditioning !== null }
//                    bold - text(Strings.reconditioning2)
//                    centered - text {
//                        ::content{ Strings.reconditioningLabel(selected.awaitNotNull().reconditioning) }
//                    }
//                }
            }
            expanding - col {
                ::exists { selected.awaitNotNull().transmission !== null || selected.awaitNotNull().fuelType !== null }

                row {
                    ::exists { selected.awaitNotNull().transmission !== null }
                    bold - text(Strings.transmission2)
                    centered - text {
                        ::content{ selected.awaitNotNull().transmission.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().fuelType !== null }
                    bold - text(Strings.fuel1)
                    centered - text {
                        ::content{ selected.awaitNotNull().fuelType.toString() }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().tires !== null }
                    bold - text(Strings.tireCondition2)
                    centered - text {
                        ::content{
                            "${
                                selected.awaitNotNull().tires?.range?.start?.times(100)?.roundToInt()
                            }% - ${
                                selected.awaitNotNull().tires?.range?.endInclusive?.times(100)
                                    ?.roundToInt()
                            }%"
                        }
                    }
                }
                row {
                    ::exists { selected.awaitNotNull().activeWarranty !== null }
                    bold - text(Strings.activeWarranty2)
                    centered - text {
                        ::content{ selected.awaitNotNull().activeWarranty?.let(Strings::yesOrNo) ?: "-" }
                    }
                }
                row {
                    val previousIterations = shared {
                        val current = selected.awaitNotNull()
                        currentSessionNullable.awaitNotNull().vehicles.query(
                            Query(
                                condition {
                                    it.vin.eq(current.vin) and
                                            it.cancellationReason.notInside(CancellationReason.PRIVACY_SENSITIVE)
                                },
                                sort { it.submitted.notNull.ascending() }
                            ))().filter { it._id != current._id }
                    }
                    exists = false
                    ::exists { previousIterations().isNotEmpty() }
                    bold - text(Strings.previously)
                    centered - text {
                        ::content{
                            previousIterations().lastOrNull()?.statusDescription() ?: ""
                        }
                    }
                }
                row {
                    bold - text {
                        wraps = false
                        content = "Location:"
                    }
                    expanding - centered - text {
                        ::content{ selected.awaitNotNull().location.toString() ?: "-" }
                    }
                }
            }
        }

        compact - row {
            ::exists{ isSeller() }
            expanding - row {
                bold - text(Strings.proxyBids1)
                centered - text {
                    ::content {
                        val b = selected()?.autobids ?: 0
                        if (b == 0) {
                            Strings.none
                        } else {
                            b.toString()
                        }
                    }
                }
            }
            expanding - row {
                ::exists{
                    selected()?.completion != null
                }
                bold - text(Strings.bids)
                centered - text {
                    ::content{
                        (selected()?.completion?.bids ?: Strings.none).toString()
                    }
                }
            }
        }
        col {
            text {
                ::exists { vehicle()?.description != null }
                ::content { vehicle()?.description ?: "No description given" }
            }
            italic - text {
                ::exists { vehicle()?.description == null }
                content = Strings.noVehicleDescriptionProvided
            }
        }
        expanding - space()
        /*col {
            ::exists{ !isSeller.await() && currentDealershipId() != selected()?.completion?.winner }
            spacing = 0.px
            h6(Strings.soldBy)
            themeFromLast { it.copy(cornerRadii = CornerRadii.Constant(0.75.rem)) } - unpadded - link {
                if (screenNavigator == dialogScreenNavigator) {
                    onNavigator = dialogScreenNavigator
                    ::to { val id = seller.awaitNotNull()._id; { SellingDialogWrapper(ProfileScreen(id)) } }
                } else {
                    ::to { val id = seller.awaitNotNull()._id; { ProfileScreen(id) } }
                }
                row {
                    dealershipShortDetails(seller.waitForNotNull, context = UsersScreen.DealerContext.Sell)
                    expanding - space()
                    centered - icon { source = Icon.chevronRight }
                }

            }
        }*/

        col {
            h6(Strings.externalInformation)
            scrollsHorizontally - row {
                data class Item(val icon: Icon, val name: String, val location: String)
                forEachUpdating(shared {
                    val sel = selected.awaitNotNull()
                    val files =
                        sel.attachments.map { Item(Icon.download, name = it.label, location = it.file.location) }
                    files + listOf(
                        Item(Icon.externalLink, "Open in CarFax", "https://www.carfaxonline.com/vhr/${sel.vin}"),
                        Item(
                            Icon.externalLink,
                            "Open AutoCheck",
                            "https://www.autocheck.com/members/singleVinSearch.do"
                        ),
                    )
                }) {
                    important - compact - externalLink {
                        newTab = true
                        ::to { it().location }
                        row {
                            centered - icon {
                                ::source { it().icon.copy(width = 1.rem, height = 1.rem) }
                            }
                            text { ::content { it().name } }
                        }
                    }
                }
            }
        }
    }
}

fun ViewWriter.vehicleActionButtons(
    selected: Readable<Vehicle?>,
    selectedLot: UUID,
    goto: suspend (vehicleId: UUID?) -> Unit,
    isSeller: Readable<Boolean>,
) {
    col {
        row {
            exists = false
            ::exists {
                isSeller() && selected()?.completion?.sold == true && selected()?.cancelled == null
            }
            compact - important - button {
                row {
                    sizeConstraints(1.5.rem, 1.5.rem) - icon(Icon.block, "")
                    centered - text(Strings.cancelTransaction)
                }
                ::exists { selected.awaitNotNull().paid == null }
                onClick {
                    dialogScreenNavigator.navigate(
                        GenericConfirmationDialog(
                            message = Strings.cancelTransactionConfirmation,
                            confirmMessage = Strings.confirm,
                            onConfirm = {
                                selected.modify(modification {
                                    it.cancelled assign now()
                                    it.cancellationReason assign CancellationReason.OFFER_DECLINED
                                    it.completion assign selected()?.completion?.copy(sold = false)
                                })
                            }
                        )
                    )
                }
            }
        }
        row {
            exists = false
            ::exists {
                selected.awaitNotNull()
                    .let { it.completion?.winner != null && it.completion?.sold == false && it.cancelled == null && isSeller() }
            }
            compact - important - button {
                compact - row {
                    sizeConstraints(1.5.rem, 1.5.rem) - icon(Icon.close, Strings.decline)
                    centered - text(Strings.decline)
                }
                onClick {
                    selected.modify(modification {
                        it.cancelled assign now()
                        it.cancellationReason assign CancellationReason.OFFER_DECLINED
                    })
                }
            }
            compact - important - button {
                compact - row {
                    sizeConstraints(1.5.rem, 1.5.rem) - icon(Icon.done, Strings.accept)
                    centered - text(Strings.accept)
                }
                onClick {
                    currentSession().vehicle.acceptInitialOffer(selectedLot)
                    currentSession().vehicles.localSignalUpdate(
                        matching = { it._id == selectedLot },
                        modify = {
                            it.copy(
                                completion = it.completion!!.copy(
                                    at = now(),
                                    sold = true,
                                )
                            )
                        }
                    )
                }
            }
        }
        row {
            row {
                exists = false
                ::exists {
                    selected.awaitNotNull()
                        .let { it.completion?.sold == true && it.completion?.winner == currentUser()?._id }
                }
                compact - important - button {
                    row {
                        sizeConstraints(1.5.rem, 1.5.rem) - icon(Icon.done, "")
                        centered - text(Strings.vehicleReceived)
                    }
                    ::exists { selected.awaitNotNull().received == null }
                    onClick {
                        currentSessionNullable.awaitNotNull().vehicles.get(selected.awaitNotNull()._id)
                            .modify(modification {
                                it.received assign now()
                            })
                    }
                } //done
            }
            row {
                exists = false
                ::exists {
                    selected.awaitNotNull()
                        .let { isSeller() && it.completion?.sold == true }
                }
                compact - important - button {
                    row {
                        sizeConstraints(1.5.rem, 1.5.rem) - icon(Icon.done, "")
                        centered - text(Strings.paymentReceived)
                    }
                    ::exists { selected.awaitNotNull().paid == null }
                    onClick {
                        currentSessionNullable.awaitNotNull().vehicles.get(selected.awaitNotNull()._id)
                            .modify(modification {
                                it.paid assign now()
                            })
                    }
                } //done diff
            }

            compact - important - button {
                compact - row {
                    icon(Icon.sync, Strings.rerunInTheUpcomingWeek)
                    centered - text(Strings.rerunInTheUpcomingWeek)
                }
                exists = false
                ::exists {
                    selected()
                        .let { it?.completion?.sold == false && isSeller() && it.cancelled != null && it.rerunStarted == null }
                }
                onClick {
                    currentSession().vehicles.get(selectedLot).modify(modification {
                        it.rerunStarted assign now()
                    })
                    currentSession().vehicles.insert(
                        selected.awaitNotNull().copy(
                            _id = uuid(),
                            submitted = now(),
                            liveAt = null,
                            completion = null,
                            paid = null,
                            received = null,
                            cancelled = null,
                            rerunStarted = null,
                            auctionLane = null,
                        )
                    )
                    goto(null)
                }
            }  //rerun
            compact - important - button {
                exists = false
                ::exists {
                    isSeller() && selected()?.liveAt == null && selected()?.completion == null
                            && selected()?.archived == null
                }
                centered - text(Strings.updateListing)
                onClick {
                    if ((selected.await()?.autobids ?: 0) == 0) navigator.navigate(
                        EditVehicleScreen(selectedLot).apply {
                            flowMode.value = false
                        }
                    )
                    else dialogScreenNavigator.navigate(ConfirmUpdateListing(selectedLot))
                }
            }
            compact - important - button {
                exists = false
                ::exists{
                    isSeller()
                            && selected()?.submitted == null
                            && selected()?.archived == null
                }
                centered - text(Strings.submitToAuction)
                onClick {
                    val v = currentSessionNullable.awaitNotNull().vehicles.get(selectedLot)
                    val v2 = v() ?: return@onClick
                    EditVehicleScreen.Tab.values().firstOrNull { it.ready(v2) != EditStatus.Complete }?.let {
                        screenNavigator.navigate(EditVehicleScreen(v2._id))
                    } ?: v.modify(modification { it.submitted assign now() })
                }
            }
            compact - danger - button {
                exists = false
                ::exists {
                    isSeller() && selected()
                        ?.let { it.liveAt == null && it.completion == null && it.submitted != null } == true
                }
                centered - text(Strings.unlist)
                onClick {
                    selected.modify(modification { it.submitted assign null })
                }
            }
            compact - danger - button {
                exists = false
                ::exists {
                    isSeller() && selected()
                        ?.let { it.submitted == null || it.cancelled != null } == true
                }
                centered - text {
                    ::content { if (selected()?.archived == null) Strings.archive else Strings.unarchive }
                }
                onClick {
                    selected.modify(modification { it.archived assign (if (selected()?.archived == null) now() else null) })
                }
            }
            important - compact - button {
                ::exists {
                    selected()?.liveAt == null && selected()?.completion == null
                            && selected()?.archived == null && !isSeller()
                }
                onClick {
                    dialogScreenNavigator.navigate(
                        SendEmailDialog(
                            onConfirm = { message ->
                                currentSessionNullable.awaitNotNull().vehicle.sendInquiryEmail(
                                    id = selectedLot,
                                    input = VehicleInquiry(
                                        question = message
                                    )
                                )
                            }
                        ))
                }
                centered - text(Strings.inquire)
            }
        }
    }
}

private suspend fun Readable<Vehicle?>.modify(modification: Modification<Vehicle>) {
    currentSessionNullable()!!.vehicles.get(awaitNotNull()._id).modify(modification)
}

fun ViewWriter.vehicleBuyer(vehicle: Readable<Vehicle?>) =
    vehicleBuyerInfo(shared { vehicle()?.completion?.winner })

fun ViewWriter.vehicleBuyerInfo(
    buyer: Readable<UUID?>
) {
    card - col {
        exists = false
        ::exists { buyer() != null }
        h2 { content = Strings.auctionWinner }
        rowCollapsingToColumn(70.rem) {
//                if (screenNavigator == dialogScreenNavigator) {
//                    onNavigator = dialogScreenNavigator
//                    ::to { val id = buyer; { SellingDialogWrapper(ProfileScreen(id))}}
//                } else {
//                    ::to { val id = buyer; { ProfileScreen(id)}}
//                }
            contactInfo(Strings.buyer, shared { currentSession().users[buyer.awaitNotNull()].awaitNotNull() })
        }
    }
}

fun ViewWriter.vehicleWinner(selected: Readable<Vehicle?>) {
    /*val winner = shared {
        val sel = selected() ?: return@shared null
        if (sel.seller == currentDealershipId())
            sel.completion?.winner?.let { currentSessionNullable.awaitNotNull().dealerships[it] }?.await()
        else null
    }
    vehicleContactInfo({ if (selected()?.completion?.sold == true) Strings.buyer else Strings.auctionWinner }, winner)*/
}

fun ViewWriter.vehiclePreviousRuns(vehicle: Readable<Vehicle?>) {
    card - col {
        ::exists { isSeller() }
        h2(Strings.previousRuns)
        val previousIterationsA = shared {
            val current = vehicle.awaitNotNull()
            currentSession().vehicles.query(
                Query(
                    condition {
                        it.vin.eq(current.vin) and
                                it._id.neq(current._id) and
                                it.cancellationReason.notInside(CancellationReason.PRIVACY_SENSITIVE)
                    },
                    sort { it.submitted.notNull.ascending() }
                ))
        }
        val previousIterations = shared { previousIterationsA()() }
        col {
            forEachUpdating(previousIterations) {
                link {
                    ::to { val id = it.awaitNotNull()._id; { VehicleDetailScreen(id) } }
                    row {
                        text {
                            ::content{
                                it().let { it.completion?.at ?: it.submitted ?: it.createdAt }.renderDateToString()
                            }
                        }
                        text("-")
                        text {
                            ::content { it().statusDescription() }
                        }
                    }
                }
            }
        }
        onlyWhen { previousIterations().isEmpty() } - text(Strings.thisIsTheFirstTimeThisVehicleHas)
    }
}

fun ViewWriter.vehicleDamage(vehicle: Readable<Vehicle?>) {
    card - col {
        h2(Strings.damage)
        val entries = shared { vehicle()?.damage ?: listOf() }
        col {
            forEach(entries) { it ->
                button {
                    onClick {
                        dialogScreenNavigator.navigate(
                            ExpandedImage(
                                it.image.location.let(
                                    ::ImageRemote
                                )
                            )
                        )
                    }
                    row {
                        sizeConstraints(width = 5.rem, height = 5.rem) - image {
                            scaleType = ImageScaleType.Crop
                            ::source {
                                it.image.let { ImageRemote(it.location) }
                            }
                            this.description = ""
                        }

                        centered - text {
                            ::content{
                                it.comment
                            }
                        }
                    }
                }
            }
        }
        italic - text {
            ::exists { entries().isEmpty() }
            content = Strings.thisVehicleHasNoReportedDamage
        }
    }
}

fun ViewWriter.vehicleConsiderations(vehicle: Readable<Vehicle?>) {
    card - col {
        h2(Strings.announcements)
        fun extraInfoField(
            name: String,
            property: (Vehicle) -> ExtraInfo?,
            icon: Icon,
        ) {
            col {
                ::exists {
                    vehicle.awaitNotNull().let { property(it) } != null
                }
                row {
                    centered - compact - icon {
                        ::source{ icon }
                    }
                    centered - expanding - h4(name)
                }
                text {
                    ::exists { vehicle.awaitNotNull().let { property(it) }?.description != null }
                    ::content{
                        vehicle.awaitNotNull().let { property(it) }?.description ?: ""
                    }
                }
                italic - text {
                    ::exists { vehicle.awaitNotNull().let { property(it) }?.description == null }
                    content = Strings.noAdditionalInformationWasProvided
                }
                space()
            }
        }


        extraInfoField(
            Strings.completelyClean,
            { it.completelyClean },
            Icon.clean
        )
        extraInfoField(
            Strings.priorAccident,
            { it.priorAccident },
            Icon.priorAccident
        )
        extraInfoField(Strings.paintWork, { it.paintwork }, Icon.paintwork)
        extraInfoField(Strings.warningLights, { it.warningLights }, Icon.warning)
        extraInfoField(Strings.towRequired, { it.towRequired }, Icon.towing)
        extraInfoField(Strings.nonRunner, { it.nonRunner }, Icon.remove)
        extraInfoField(
            Strings.structuralDamage,
            { it.structuralDamage },
            Icon.damage
        )
        extraInfoField(
            Strings.airConditioningIssue,
            { it.airConditioningIssue },
            Icon.acIssues
        )
        extraInfoField(
            Strings.transmissionIssue,
            { it.transmissionIssue },
            Icon.manual
        )
        extraInfoField(
            Strings.odometerIssue,
            { it.odometerIssue },
            Icon.speedometer
        )
        extraInfoField(Strings.canadian, { it.canadian }, Icon.canada)
        // extraInfoField(Strings.titleNotPresent, { it.titleNotPresent }, Icon.missingTitle)
        extraInfoField(Strings.salvage, { it.salvage }, Icon.recycling)
        extraInfoField(Strings.lemonLaw, { it.lemonLaw }, Icon.lemon)
        extraInfoField(Strings.flood, { it.flood }, Icon.waterDrop)
        extraInfoField(Strings.stolenRecovery, { it.stolenOrRecovery }, Icon.money)
        extraInfoField(Strings.rentalTaxi, { it.rentalOrTaxi }, Icon.taxi)
        extraInfoField(Strings.trueMileageUnknown, { it.trueMileageUnknown }, Icon.close)
    }
}
