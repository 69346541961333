// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareItemFields() {
    val props: Array<SerializableProperty<Item, *>> = arrayOf(Item__id, Item_creation)
    Item.serializer().properties { props }
}
val <K> DataClassPath<K, Item>._id: DataClassPath<K, Int> get() = this[Item__id]
val <K> DataClassPath<K, Item>.creation: DataClassPath<K, Int> get() = this[Item_creation]
inline val Item.Companion.path: DataClassPath<Item, Item> get() = path<Item>()


object Item__id: SerializableProperty<Item, Int> {
    override val name: String = "_id"
    override fun get(receiver: Item): Int = receiver._id
    override fun setCopy(receiver: Item, value: Int) = receiver.copy(_id = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Item.serializer().tryFindAnnotations("_id")
}
object Item_creation: SerializableProperty<Item, Int> {
    override val name: String = "creation"
    override fun get(receiver: Item): Int = receiver.creation
    override fun setCopy(receiver: Item, value: Int) = receiver.copy(creation = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = Item.serializer().tryFindAnnotations("creation")
    override val default: Int = 0 // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)creation([^a-zA-Z0-9]|$)
}
