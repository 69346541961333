// Automatically generated based off OtpSecret.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.auth.proof

import com.lightningkite.UUID
import com.lightningkite.lightningdb.ExperimentalLightningServer
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.time.Duration
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlin.time.Duration
fun preparePasswordSecretFields() {
    val props: Array<SerializableProperty<PasswordSecret, *>> = arrayOf(PasswordSecret__id, PasswordSecret_subjectType, PasswordSecret_subjectId, PasswordSecret_hash, PasswordSecret_hint, PasswordSecret_establishedAt, PasswordSecret_lastUsedAt, PasswordSecret_expiresAt, PasswordSecret_disabledAt)
    PasswordSecret.serializer().properties { props }
}
val <K> DataClassPath<K, PasswordSecret>._id: DataClassPath<K, UUID> get() = this[PasswordSecret__id]
val <K> DataClassPath<K, PasswordSecret>.subjectType: DataClassPath<K, String> get() = this[PasswordSecret_subjectType]
val <K> DataClassPath<K, PasswordSecret>.subjectId: DataClassPath<K, String> get() = this[PasswordSecret_subjectId]
val <K> DataClassPath<K, PasswordSecret>.hash: DataClassPath<K, String> get() = this[PasswordSecret_hash]
val <K> DataClassPath<K, PasswordSecret>.hint: DataClassPath<K, String?> get() = this[PasswordSecret_hint]
val <K> DataClassPath<K, PasswordSecret>.establishedAt: DataClassPath<K, Instant> get() = this[PasswordSecret_establishedAt]
val <K> DataClassPath<K, PasswordSecret>.lastUsedAt: DataClassPath<K, Instant?> get() = this[PasswordSecret_lastUsedAt]
val <K> DataClassPath<K, PasswordSecret>.expiresAt: DataClassPath<K, Instant?> get() = this[PasswordSecret_expiresAt]
val <K> DataClassPath<K, PasswordSecret>.disabledAt: DataClassPath<K, Instant?> get() = this[PasswordSecret_disabledAt]
inline val PasswordSecret.Companion.path: DataClassPath<PasswordSecret, PasswordSecret> get() = path<PasswordSecret>()


object PasswordSecret__id: SerializableProperty<PasswordSecret, UUID> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(0) }
    override fun get(receiver: PasswordSecret): UUID = receiver._id
    override fun setCopy(receiver: PasswordSecret, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hint([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object PasswordSecret_subjectType: SerializableProperty<PasswordSecret, String> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(1) }
    override fun get(receiver: PasswordSecret): String = receiver.subjectType
    override fun setCopy(receiver: PasswordSecret, value: String) = receiver.copy(subjectType = value)
    override val serializer: KSerializer<String> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(1) }
}
object PasswordSecret_subjectId: SerializableProperty<PasswordSecret, String> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(2) }
    override fun get(receiver: PasswordSecret): String = receiver.subjectId
    override fun setCopy(receiver: PasswordSecret, value: String) = receiver.copy(subjectId = value)
    override val serializer: KSerializer<String> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(2) }
}
object PasswordSecret_hash: SerializableProperty<PasswordSecret, String> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(3) }
    override fun get(receiver: PasswordSecret): String = receiver.hash
    override fun setCopy(receiver: PasswordSecret, value: String) = receiver.copy(hash = value)
    override val serializer: KSerializer<String> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(3) }
}
object PasswordSecret_hint: SerializableProperty<PasswordSecret, String?> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(4) }
    override fun get(receiver: PasswordSecret): String? = receiver.hint
    override fun setCopy(receiver: PasswordSecret, value: String?) = receiver.copy(hint = value)
    override val serializer: KSerializer<String?> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String?> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(4) }
    override val default: String? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hint([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object PasswordSecret_establishedAt: SerializableProperty<PasswordSecret, Instant> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(5) }
    override fun get(receiver: PasswordSecret): Instant = receiver.establishedAt
    override fun setCopy(receiver: PasswordSecret, value: Instant) = receiver.copy(establishedAt = value)
    override val serializer: KSerializer<Instant> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(5) }
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hint([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object PasswordSecret_lastUsedAt: SerializableProperty<PasswordSecret, Instant?> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(6) }
    override fun get(receiver: PasswordSecret): Instant? = receiver.lastUsedAt
    override fun setCopy(receiver: PasswordSecret, value: Instant?) = receiver.copy(lastUsedAt = value)
    override val serializer: KSerializer<Instant?> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(6) }
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hint([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object PasswordSecret_expiresAt: SerializableProperty<PasswordSecret, Instant?> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(7) }
    override fun get(receiver: PasswordSecret): Instant? = receiver.expiresAt
    override fun setCopy(receiver: PasswordSecret, value: Instant?) = receiver.copy(expiresAt = value)
    override val serializer: KSerializer<Instant?> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(7) }
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hint([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object PasswordSecret_disabledAt: SerializableProperty<PasswordSecret, Instant?> {
    override val name: String by lazy { PasswordSecret.serializer().descriptor.getElementName(8) }
    override fun get(receiver: PasswordSecret): Instant? = receiver.disabledAt
    override fun setCopy(receiver: PasswordSecret, value: Instant?) = receiver.copy(disabledAt = value)
    override val serializer: KSerializer<Instant?> by lazy { (PasswordSecret.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { PasswordSecret.serializer().descriptor.getElementAnnotations(8) }
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hash([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hint([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
