// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareAuctionLaneFields() {
    val props: Array<SerializableProperty<AuctionLane, *>> = arrayOf(AuctionLane__id, AuctionLane_scheduledStart, AuctionLane_name, AuctionLane_description, AuctionLane_condition, AuctionLane_active, AuctionLane_runningAuction)
    AuctionLane.serializer().properties { props }
}
val <K> DataClassPath<K, AuctionLane>._id: DataClassPath<K, UUID> get() = this[AuctionLane__id]
val <K> DataClassPath<K, AuctionLane>.scheduledStart: DataClassPath<K, LocalDateTime> get() = this[AuctionLane_scheduledStart]
val <K> DataClassPath<K, AuctionLane>.name: DataClassPath<K, String> get() = this[AuctionLane_name]
val <K> DataClassPath<K, AuctionLane>.description: DataClassPath<K, String> get() = this[AuctionLane_description]
val <K> DataClassPath<K, AuctionLane>.condition: DataClassPath<K, Condition<Vehicle>> get() = this[AuctionLane_condition]
val <K> DataClassPath<K, AuctionLane>.active: DataClassPath<K, Boolean> get() = this[AuctionLane_active]
val <K> DataClassPath<K, AuctionLane>.runningAuction: DataClassPath<K, UUID?> get() = this[AuctionLane_runningAuction]
inline val AuctionLane.Companion.path: DataClassPath<AuctionLane, AuctionLane> get() = path<AuctionLane>()


object AuctionLane__id: SerializableProperty<AuctionLane, UUID> {
    override val name: String = "_id"
    override fun get(receiver: AuctionLane): UUID = receiver._id
    override fun setCopy(receiver: AuctionLane, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scheduledStart([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)name([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)description([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)condition([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)active([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)runningAuction([^a-zA-Z0-9]|$)
}
object AuctionLane_scheduledStart: SerializableProperty<AuctionLane, LocalDateTime> {
    override val name: String = "scheduledStart"
    override fun get(receiver: AuctionLane): LocalDateTime = receiver.scheduledStart
    override fun setCopy(receiver: AuctionLane, value: LocalDateTime) = receiver.copy(scheduledStart = value)
    override val serializer: KSerializer<LocalDateTime> = LocalDateTime.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("scheduledStart")
    override val default: LocalDateTime = run {
        val now = now().atZone(auctionZone)
        var current = now.date
        while (current.dayOfWeek != DayOfWeek.FRIDAY) {
            current += DatePeriod(days = 1)
        }
        LocalDateTime(current, LocalTime(12, 0, 0))
    } // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scheduledStart([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)name([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)description([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)condition([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)active([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)runningAuction([^a-zA-Z0-9]|$)
}
object AuctionLane_name: SerializableProperty<AuctionLane, String> {
    override val name: String = "name"
    override fun get(receiver: AuctionLane): String = receiver.name
    override fun setCopy(receiver: AuctionLane, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("name")
}
object AuctionLane_description: SerializableProperty<AuctionLane, String> {
    override val name: String = "description"
    override fun get(receiver: AuctionLane): String = receiver.description
    override fun setCopy(receiver: AuctionLane, value: String) = receiver.copy(description = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("description")
}
object AuctionLane_condition: SerializableProperty<AuctionLane, Condition<Vehicle>> {
    override val name: String = "condition"
    override fun get(receiver: AuctionLane): Condition<Vehicle> = receiver.condition
    override fun setCopy(receiver: AuctionLane, value: Condition<Vehicle>) = receiver.copy(condition = value)
    override val serializer: KSerializer<Condition<Vehicle>> = Condition.serializer(Vehicle.serializer())
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("condition")
    override val default: Condition<Vehicle> = Condition.Always // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scheduledStart([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)name([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)description([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)condition([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)active([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)runningAuction([^a-zA-Z0-9]|$)
}
object AuctionLane_active: SerializableProperty<AuctionLane, Boolean> {
    override val name: String = "active"
    override fun get(receiver: AuctionLane): Boolean = receiver.active
    override fun setCopy(receiver: AuctionLane, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("active")
    override val default: Boolean = true // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scheduledStart([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)name([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)description([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)condition([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)active([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)runningAuction([^a-zA-Z0-9]|$)
}
object AuctionLane_runningAuction: SerializableProperty<AuctionLane, UUID?> {
    override val name: String = "runningAuction"
    override fun get(receiver: AuctionLane): UUID? = receiver.runningAuction
    override fun setCopy(receiver: AuctionLane, value: UUID?) = receiver.copy(runningAuction = value)
    override val serializer: KSerializer<UUID?> = ContextualSerializer(UUID::class).nullable2
    override val annotations: List<Annotation> = AuctionLane.serializer().tryFindAnnotations("runningAuction")
    override val default: UUID? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)scheduledStart([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)name([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)description([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)condition([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)active([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)runningAuction([^a-zA-Z0-9]|$)
}
