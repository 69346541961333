// Automatically generated based off OtpSecret.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.lightningkite.lightningserver.auth.proof

import com.lightningkite.UUID
import com.lightningkite.lightningdb.ExperimentalLightningServer
import com.lightningkite.lightningdb.GenerateDataClassPaths
import com.lightningkite.lightningdb.HasId
import com.lightningkite.now
import com.lightningkite.uuid
import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import kotlin.time.Duration
import com.lightningkite.serialization.*
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import com.lightningkite.lightningdb.*
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer
import kotlinx.datetime.*
import com.lightningkite.*

// Contextual types: kotlin.time.Duration
fun prepareOtpSecretFields() {
    val props: Array<SerializableProperty<OtpSecret, *>> = arrayOf(OtpSecret__id, OtpSecret_subjectType, OtpSecret_subjectId, OtpSecret_secretBase32, OtpSecret_label, OtpSecret_issuer, OtpSecret_period, OtpSecret_digits, OtpSecret_algorithm, OtpSecret_establishedAt, OtpSecret_lastUsedAt, OtpSecret_expiresAt, OtpSecret_disabledAt)
    OtpSecret.serializer().properties { props }
}
val <K> DataClassPath<K, OtpSecret>._id: DataClassPath<K, UUID> get() = this[OtpSecret__id]
val <K> DataClassPath<K, OtpSecret>.subjectType: DataClassPath<K, String> get() = this[OtpSecret_subjectType]
val <K> DataClassPath<K, OtpSecret>.subjectId: DataClassPath<K, String> get() = this[OtpSecret_subjectId]
val <K> DataClassPath<K, OtpSecret>.secretBase32: DataClassPath<K, String> get() = this[OtpSecret_secretBase32]
val <K> DataClassPath<K, OtpSecret>.label: DataClassPath<K, String> get() = this[OtpSecret_label]
val <K> DataClassPath<K, OtpSecret>.issuer: DataClassPath<K, String> get() = this[OtpSecret_issuer]
val <K> DataClassPath<K, OtpSecret>.period: DataClassPath<K, Duration> get() = this[OtpSecret_period]
val <K> DataClassPath<K, OtpSecret>.digits: DataClassPath<K, Int> get() = this[OtpSecret_digits]
val <K> DataClassPath<K, OtpSecret>.algorithm: DataClassPath<K, OtpHashAlgorithm> get() = this[OtpSecret_algorithm]
val <K> DataClassPath<K, OtpSecret>.establishedAt: DataClassPath<K, Instant> get() = this[OtpSecret_establishedAt]
val <K> DataClassPath<K, OtpSecret>.lastUsedAt: DataClassPath<K, Instant?> get() = this[OtpSecret_lastUsedAt]
val <K> DataClassPath<K, OtpSecret>.expiresAt: DataClassPath<K, Instant?> get() = this[OtpSecret_expiresAt]
val <K> DataClassPath<K, OtpSecret>.disabledAt: DataClassPath<K, Instant?> get() = this[OtpSecret_disabledAt]
inline val OtpSecret.Companion.path: DataClassPath<OtpSecret, OtpSecret> get() = path<OtpSecret>()


object OtpSecret__id: SerializableProperty<OtpSecret, UUID> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(0) }
    override fun get(receiver: OtpSecret): UUID = receiver._id
    override fun setCopy(receiver: OtpSecret, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[0] as KSerializer<UUID> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(0) }
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretBase32([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)issuer([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)period([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)digits([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)algorithm([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object OtpSecret_subjectType: SerializableProperty<OtpSecret, String> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(1) }
    override fun get(receiver: OtpSecret): String = receiver.subjectType
    override fun setCopy(receiver: OtpSecret, value: String) = receiver.copy(subjectType = value)
    override val serializer: KSerializer<String> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[1] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(1) }
}
object OtpSecret_subjectId: SerializableProperty<OtpSecret, String> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(2) }
    override fun get(receiver: OtpSecret): String = receiver.subjectId
    override fun setCopy(receiver: OtpSecret, value: String) = receiver.copy(subjectId = value)
    override val serializer: KSerializer<String> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[2] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(2) }
}
object OtpSecret_secretBase32: SerializableProperty<OtpSecret, String> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(3) }
    override fun get(receiver: OtpSecret): String = receiver.secretBase32
    override fun setCopy(receiver: OtpSecret, value: String) = receiver.copy(secretBase32 = value)
    override val serializer: KSerializer<String> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[3] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(3) }
}
object OtpSecret_label: SerializableProperty<OtpSecret, String> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(4) }
    override fun get(receiver: OtpSecret): String = receiver.label
    override fun setCopy(receiver: OtpSecret, value: String) = receiver.copy(label = value)
    override val serializer: KSerializer<String> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[4] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(4) }
}
object OtpSecret_issuer: SerializableProperty<OtpSecret, String> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(5) }
    override fun get(receiver: OtpSecret): String = receiver.issuer
    override fun setCopy(receiver: OtpSecret, value: String) = receiver.copy(issuer = value)
    override val serializer: KSerializer<String> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[5] as KSerializer<String> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(5) }
}
object OtpSecret_period: SerializableProperty<OtpSecret, Duration> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(6) }
    override fun get(receiver: OtpSecret): Duration = receiver.period
    override fun setCopy(receiver: OtpSecret, value: Duration) = receiver.copy(period = value)
    override val serializer: KSerializer<Duration> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[6] as KSerializer<Duration> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(6) }
}
object OtpSecret_digits: SerializableProperty<OtpSecret, Int> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(7) }
    override fun get(receiver: OtpSecret): Int = receiver.digits
    override fun setCopy(receiver: OtpSecret, value: Int) = receiver.copy(digits = value)
    override val serializer: KSerializer<Int> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[7] as KSerializer<Int> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(7) }
}
object OtpSecret_algorithm: SerializableProperty<OtpSecret, OtpHashAlgorithm> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(8) }
    override fun get(receiver: OtpSecret): OtpHashAlgorithm = receiver.algorithm
    override fun setCopy(receiver: OtpSecret, value: OtpHashAlgorithm) = receiver.copy(algorithm = value)
    override val serializer: KSerializer<OtpHashAlgorithm> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[8] as KSerializer<OtpHashAlgorithm> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(8) }
}
object OtpSecret_establishedAt: SerializableProperty<OtpSecret, Instant> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(9) }
    override fun get(receiver: OtpSecret): Instant = receiver.establishedAt
    override fun setCopy(receiver: OtpSecret, value: Instant) = receiver.copy(establishedAt = value)
    override val serializer: KSerializer<Instant> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[9] as KSerializer<Instant> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(9) }
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretBase32([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)issuer([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)period([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)digits([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)algorithm([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object OtpSecret_lastUsedAt: SerializableProperty<OtpSecret, Instant?> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(10) }
    override fun get(receiver: OtpSecret): Instant? = receiver.lastUsedAt
    override fun setCopy(receiver: OtpSecret, value: Instant?) = receiver.copy(lastUsedAt = value)
    override val serializer: KSerializer<Instant?> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[10] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(10) }
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretBase32([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)issuer([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)period([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)digits([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)algorithm([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object OtpSecret_expiresAt: SerializableProperty<OtpSecret, Instant?> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(11) }
    override fun get(receiver: OtpSecret): Instant? = receiver.expiresAt
    override fun setCopy(receiver: OtpSecret, value: Instant?) = receiver.copy(expiresAt = value)
    override val serializer: KSerializer<Instant?> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[11] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(11) }
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretBase32([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)issuer([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)period([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)digits([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)algorithm([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
object OtpSecret_disabledAt: SerializableProperty<OtpSecret, Instant?> {
    override val name: String by lazy { OtpSecret.serializer().descriptor.getElementName(12) }
    override fun get(receiver: OtpSecret): Instant? = receiver.disabledAt
    override fun setCopy(receiver: OtpSecret, value: Instant?) = receiver.copy(disabledAt = value)
    override val serializer: KSerializer<Instant?> by lazy { (OtpSecret.serializer() as GeneratedSerializer<*>).childSerializers()[12] as KSerializer<Instant?> }
    override val annotations: List<Annotation> by lazy { OtpSecret.serializer().descriptor.getElementAnnotations(12) }
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectType([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)subjectId([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)secretBase32([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)label([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)issuer([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)period([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)digits([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)algorithm([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)establishedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastUsedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)expiresAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)disabledAt([^a-zA-Z0-9]|$)
}
