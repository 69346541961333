// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareLiveAuctionDataFields() {
    val props: Array<SerializableProperty<LiveAuctionData, *>> = arrayOf(LiveAuctionData__id, LiveAuctionData_currentVehicle, LiveAuctionData_winningBid, LiveAuctionData_base, LiveAuctionData_increment, LiveAuctionData_startedAt, LiveAuctionData_laneStartedAt, LiveAuctionData_lastAskChange, LiveAuctionData_timeout, LiveAuctionData_dropReserveHovered, LiveAuctionData_hovers, LiveAuctionData_participants, LiveAuctionData_dropsLeft, LiveAuctionData_bids, LiveAuctionData_completion, LiveAuctionData_reserveMet, LiveAuctionData_talkingPointUsed)
    LiveAuctionData.serializer().properties { props }
}
val <K> DataClassPath<K, LiveAuctionData>._id: DataClassPath<K, UUID> get() = this[LiveAuctionData__id]
val <K> DataClassPath<K, LiveAuctionData>.currentVehicle: DataClassPath<K, UUID> get() = this[LiveAuctionData_currentVehicle]
val <K> DataClassPath<K, LiveAuctionData>.winningBid: DataClassPath<K, Bid?> get() = this[LiveAuctionData_winningBid]
val <K> DataClassPath<K, LiveAuctionData>.base: DataClassPath<K, PriceInDollars> get() = this[LiveAuctionData_base]
val <K> DataClassPath<K, LiveAuctionData>.increment: DataClassPath<K, RoundingLevel> get() = this[LiveAuctionData_increment]
val <K> DataClassPath<K, LiveAuctionData>.startedAt: DataClassPath<K, Instant> get() = this[LiveAuctionData_startedAt]
val <K> DataClassPath<K, LiveAuctionData>.laneStartedAt: DataClassPath<K, Instant> get() = this[LiveAuctionData_laneStartedAt]
val <K> DataClassPath<K, LiveAuctionData>.lastAskChange: DataClassPath<K, Instant> get() = this[LiveAuctionData_lastAskChange]
val <K> DataClassPath<K, LiveAuctionData>.timeout: DataClassPath<K, Instant> get() = this[LiveAuctionData_timeout]
val <K> DataClassPath<K, LiveAuctionData>.dropReserveHovered: DataClassPath<K, Boolean> get() = this[LiveAuctionData_dropReserveHovered]
val <K> DataClassPath<K, LiveAuctionData>.hovers: DataClassPath<K, Set<UUID>> get() = this[LiveAuctionData_hovers]
val <K> DataClassPath<K, LiveAuctionData>.participants: DataClassPath<K, Set<UUID>> get() = this[LiveAuctionData_participants]
val <K> DataClassPath<K, LiveAuctionData>.dropsLeft: DataClassPath<K, Int> get() = this[LiveAuctionData_dropsLeft]
val <K> DataClassPath<K, LiveAuctionData>.bids: DataClassPath<K, Int> get() = this[LiveAuctionData_bids]
val <K> DataClassPath<K, LiveAuctionData>.completion: DataClassPath<K, Completion?> get() = this[LiveAuctionData_completion]
val <K> DataClassPath<K, LiveAuctionData>.reserveMet: DataClassPath<K, Boolean> get() = this[LiveAuctionData_reserveMet]
val <K> DataClassPath<K, LiveAuctionData>.talkingPointUsed: DataClassPath<K, Boolean> get() = this[LiveAuctionData_talkingPointUsed]
inline val LiveAuctionData.Companion.path: DataClassPath<LiveAuctionData, LiveAuctionData> get() = path<LiveAuctionData>()


object LiveAuctionData__id: SerializableProperty<LiveAuctionData, UUID> {
    override val name: String = "_id"
    override fun get(receiver: LiveAuctionData): UUID = receiver._id
    override fun setCopy(receiver: LiveAuctionData, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("_id")
}
object LiveAuctionData_currentVehicle: SerializableProperty<LiveAuctionData, UUID> {
    override val name: String = "currentVehicle"
    override fun get(receiver: LiveAuctionData): UUID = receiver.currentVehicle
    override fun setCopy(receiver: LiveAuctionData, value: UUID) = receiver.copy(currentVehicle = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("currentVehicle")
}
object LiveAuctionData_winningBid: SerializableProperty<LiveAuctionData, Bid?> {
    override val name: String = "winningBid"
    override fun get(receiver: LiveAuctionData): Bid? = receiver.winningBid
    override fun setCopy(receiver: LiveAuctionData, value: Bid?) = receiver.copy(winningBid = value)
    override val serializer: KSerializer<Bid?> = Bid.serializer().nullable2
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("winningBid")
    override val default: Bid? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_base: SerializableProperty<LiveAuctionData, PriceInDollars> {
    override val name: String = "base"
    override fun get(receiver: LiveAuctionData): PriceInDollars = receiver.base
    override fun setCopy(receiver: LiveAuctionData, value: PriceInDollars) = receiver.copy(base = value)
    override val serializer: KSerializer<PriceInDollars> = PriceInDollars.serializer()
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("base")
    override val default: PriceInDollars = 100 // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_increment: SerializableProperty<LiveAuctionData, RoundingLevel> {
    override val name: String = "increment"
    override fun get(receiver: LiveAuctionData): RoundingLevel = receiver.increment
    override fun setCopy(receiver: LiveAuctionData, value: RoundingLevel) = receiver.copy(increment = value)
    override val serializer: KSerializer<RoundingLevel> = RoundingLevel.serializer()
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("increment")
}
object LiveAuctionData_startedAt: SerializableProperty<LiveAuctionData, Instant> {
    override val name: String = "startedAt"
    override fun get(receiver: LiveAuctionData): Instant = receiver.startedAt
    override fun setCopy(receiver: LiveAuctionData, value: Instant) = receiver.copy(startedAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("startedAt")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_laneStartedAt: SerializableProperty<LiveAuctionData, Instant> {
    override val name: String = "laneStartedAt"
    override fun get(receiver: LiveAuctionData): Instant = receiver.laneStartedAt
    override fun setCopy(receiver: LiveAuctionData, value: Instant) = receiver.copy(laneStartedAt = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("laneStartedAt")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_lastAskChange: SerializableProperty<LiveAuctionData, Instant> {
    override val name: String = "lastAskChange"
    override fun get(receiver: LiveAuctionData): Instant = receiver.lastAskChange
    override fun setCopy(receiver: LiveAuctionData, value: Instant) = receiver.copy(lastAskChange = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("lastAskChange")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_timeout: SerializableProperty<LiveAuctionData, Instant> {
    override val name: String = "timeout"
    override fun get(receiver: LiveAuctionData): Instant = receiver.timeout
    override fun setCopy(receiver: LiveAuctionData, value: Instant) = receiver.copy(timeout = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("timeout")
    override val default: Instant = now() + LiveAuctionData.initialDropDelay // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_dropReserveHovered: SerializableProperty<LiveAuctionData, Boolean> {
    override val name: String = "dropReserveHovered"
    override fun get(receiver: LiveAuctionData): Boolean = receiver.dropReserveHovered
    override fun setCopy(receiver: LiveAuctionData, value: Boolean) = receiver.copy(dropReserveHovered = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("dropReserveHovered")
    override val default: Boolean = false // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_hovers: SerializableProperty<LiveAuctionData, Set<UUID>> {
    override val name: String = "hovers"
    override fun get(receiver: LiveAuctionData): Set<UUID> = receiver.hovers
    override fun setCopy(receiver: LiveAuctionData, value: Set<UUID>) = receiver.copy(hovers = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("hovers")
    override val default: Set<UUID> = setOf() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_participants: SerializableProperty<LiveAuctionData, Set<UUID>> {
    override val name: String = "participants"
    override fun get(receiver: LiveAuctionData): Set<UUID> = receiver.participants
    override fun setCopy(receiver: LiveAuctionData, value: Set<UUID>) = receiver.copy(participants = value)
    override val serializer: KSerializer<Set<UUID>> = SetSerializer(ContextualSerializer(UUID::class))
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("participants")
    override val default: Set<UUID> = setOf() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_dropsLeft: SerializableProperty<LiveAuctionData, Int> {
    override val name: String = "dropsLeft"
    override fun get(receiver: LiveAuctionData): Int = receiver.dropsLeft
    override fun setCopy(receiver: LiveAuctionData, value: Int) = receiver.copy(dropsLeft = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("dropsLeft")
    override val default: Int = 3 // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_bids: SerializableProperty<LiveAuctionData, Int> {
    override val name: String = "bids"
    override fun get(receiver: LiveAuctionData): Int = receiver.bids
    override fun setCopy(receiver: LiveAuctionData, value: Int) = receiver.copy(bids = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("bids")
    override val default: Int = 0 // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_completion: SerializableProperty<LiveAuctionData, Completion?> {
    override val name: String = "completion"
    override fun get(receiver: LiveAuctionData): Completion? = receiver.completion
    override fun setCopy(receiver: LiveAuctionData, value: Completion?) = receiver.copy(completion = value)
    override val serializer: KSerializer<Completion?> = Completion.serializer().nullable2
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("completion")
    override val default: Completion? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_reserveMet: SerializableProperty<LiveAuctionData, Boolean> {
    override val name: String = "reserveMet"
    override fun get(receiver: LiveAuctionData): Boolean = receiver.reserveMet
    override fun setCopy(receiver: LiveAuctionData, value: Boolean) = receiver.copy(reserveMet = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("reserveMet")
    override val default: Boolean = false // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
object LiveAuctionData_talkingPointUsed: SerializableProperty<LiveAuctionData, Boolean> {
    override val name: String = "talkingPointUsed"
    override fun get(receiver: LiveAuctionData): Boolean = receiver.talkingPointUsed
    override fun setCopy(receiver: LiveAuctionData, value: Boolean) = receiver.copy(talkingPointUsed = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = LiveAuctionData.serializer().tryFindAnnotations("talkingPointUsed")
    override val default: Boolean = false // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)currentVehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)winningBid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)base([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)increment([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)startedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)laneStartedAt([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)lastAskChange([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)timeout([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropReserveHovered([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)hovers([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)participants([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)dropsLeft([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bids([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)completion([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)reserveMet([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)talkingPointUsed([^a-zA-Z0-9]|$)
}
