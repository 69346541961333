// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareEarnestPaymentFields() {
    val props: Array<SerializableProperty<EarnestPayment, *>> = arrayOf(EarnestPayment__id, EarnestPayment_user, EarnestPayment_at, EarnestPayment_method, EarnestPayment_priceInCents)
    EarnestPayment.serializer().properties { props }
}
val <K> DataClassPath<K, EarnestPayment>._id: DataClassPath<K, UUID> get() = this[EarnestPayment__id]
val <K> DataClassPath<K, EarnestPayment>.user: DataClassPath<K, UUID> get() = this[EarnestPayment_user]
val <K> DataClassPath<K, EarnestPayment>.at: DataClassPath<K, Instant> get() = this[EarnestPayment_at]
val <K> DataClassPath<K, EarnestPayment>.method: DataClassPath<K, String> get() = this[EarnestPayment_method]
val <K> DataClassPath<K, EarnestPayment>.priceInCents: DataClassPath<K, Int> get() = this[EarnestPayment_priceInCents]
inline val EarnestPayment.Companion.path: DataClassPath<EarnestPayment, EarnestPayment> get() = path<EarnestPayment>()


object EarnestPayment__id: SerializableProperty<EarnestPayment, UUID> {
    override val name: String = "_id"
    override fun get(receiver: EarnestPayment): UUID = receiver._id
    override fun setCopy(receiver: EarnestPayment, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = EarnestPayment.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)at([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)method([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)priceInCents([^a-zA-Z0-9]|$)
}
object EarnestPayment_user: SerializableProperty<EarnestPayment, UUID> {
    override val name: String = "user"
    override fun get(receiver: EarnestPayment): UUID = receiver.user
    override fun setCopy(receiver: EarnestPayment, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = EarnestPayment.serializer().tryFindAnnotations("user")
}
object EarnestPayment_at: SerializableProperty<EarnestPayment, Instant> {
    override val name: String = "at"
    override fun get(receiver: EarnestPayment): Instant = receiver.at
    override fun setCopy(receiver: EarnestPayment, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = EarnestPayment.serializer().tryFindAnnotations("at")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)at([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)method([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)priceInCents([^a-zA-Z0-9]|$)
}
object EarnestPayment_method: SerializableProperty<EarnestPayment, String> {
    override val name: String = "method"
    override fun get(receiver: EarnestPayment): String = receiver.method
    override fun setCopy(receiver: EarnestPayment, value: String) = receiver.copy(method = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = EarnestPayment.serializer().tryFindAnnotations("method")
    override val default: String = "Not recorded" // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)at([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)method([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)priceInCents([^a-zA-Z0-9]|$)
}
object EarnestPayment_priceInCents: SerializableProperty<EarnestPayment, Int> {
    override val name: String = "priceInCents"
    override fun get(receiver: EarnestPayment): Int = receiver.priceInCents
    override fun setCopy(receiver: EarnestPayment, value: Int) = receiver.copy(priceInCents = value)
    override val serializer: KSerializer<Int> = Int.serializer()
    override val annotations: List<Annotation> = EarnestPayment.serializer().tryFindAnnotations("priceInCents")
    override val default: Int = 0 // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)at([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)method([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)priceInCents([^a-zA-Z0-9]|$)
}
