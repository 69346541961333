// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareBidFields() {
    val props: Array<SerializableProperty<Bid, *>> = arrayOf(Bid__id, Bid_vehicle, Bid_buyer, Bid_price, Bid_at)
    Bid.serializer().properties { props }
}
val <K> DataClassPath<K, Bid>._id: DataClassPath<K, UUID> get() = this[Bid__id]
val <K> DataClassPath<K, Bid>.vehicle: DataClassPath<K, UUID> get() = this[Bid_vehicle]
val <K> DataClassPath<K, Bid>.buyer: DataClassPath<K, UUID> get() = this[Bid_buyer]
val <K> DataClassPath<K, Bid>.price: DataClassPath<K, PriceInDollars> get() = this[Bid_price]
val <K> DataClassPath<K, Bid>.at: DataClassPath<K, Instant> get() = this[Bid_at]
inline val Bid.Companion.path: DataClassPath<Bid, Bid> get() = path<Bid>()


object Bid__id: SerializableProperty<Bid, UUID> {
    override val name: String = "_id"
    override fun get(receiver: Bid): UUID = receiver._id
    override fun setCopy(receiver: Bid, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Bid.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)buyer([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)price([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)at([^a-zA-Z0-9]|$)
}
object Bid_vehicle: SerializableProperty<Bid, UUID> {
    override val name: String = "vehicle"
    override fun get(receiver: Bid): UUID = receiver.vehicle
    override fun setCopy(receiver: Bid, value: UUID) = receiver.copy(vehicle = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Bid.serializer().tryFindAnnotations("vehicle")
}
object Bid_buyer: SerializableProperty<Bid, UUID> {
    override val name: String = "buyer"
    override fun get(receiver: Bid): UUID = receiver.buyer
    override fun setCopy(receiver: Bid, value: UUID) = receiver.copy(buyer = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = Bid.serializer().tryFindAnnotations("buyer")
}
object Bid_price: SerializableProperty<Bid, PriceInDollars> {
    override val name: String = "price"
    override fun get(receiver: Bid): PriceInDollars = receiver.price
    override fun setCopy(receiver: Bid, value: PriceInDollars) = receiver.copy(price = value)
    override val serializer: KSerializer<PriceInDollars> = PriceInDollars.serializer()
    override val annotations: List<Annotation> = Bid.serializer().tryFindAnnotations("price")
}
object Bid_at: SerializableProperty<Bid, Instant> {
    override val name: String = "at"
    override fun get(receiver: Bid): Instant = receiver.at
    override fun setCopy(receiver: Bid, value: Instant) = receiver.copy(at = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = Bid.serializer().tryFindAnnotations("at")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicle([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)buyer([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)price([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)at([^a-zA-Z0-9]|$)
}
