// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareVehicleRelationshipFields() {
    val props: Array<SerializableProperty<VehicleRelationship, *>> = arrayOf(VehicleRelationship__id, VehicleRelationship_vehicleDenormalizedInfo, VehicleRelationship_firstInteraction, VehicleRelationship_bidPlaced, VehicleRelationship_favorite, VehicleRelationship_notifications, VehicleRelationship_autobid, VehicleRelationship_autoBidsPlaced, VehicleRelationship_autobidLastActivated, VehicleRelationship_autobidBeaten, VehicleRelationship_personalNotes)
    VehicleRelationship.serializer().properties { props }
}
val <K> DataClassPath<K, VehicleRelationship>._id: DataClassPath<K, UserVehiclePair> get() = this[VehicleRelationship__id]
val <K> DataClassPath<K, VehicleRelationship>.vehicleDenormalizedInfo: DataClassPath<K, ShortVehicle> get() = this[VehicleRelationship_vehicleDenormalizedInfo]
val <K> DataClassPath<K, VehicleRelationship>.firstInteraction: DataClassPath<K, Instant> get() = this[VehicleRelationship_firstInteraction]
val <K> DataClassPath<K, VehicleRelationship>.bidPlaced: DataClassPath<K, Instant?> get() = this[VehicleRelationship_bidPlaced]
val <K> DataClassPath<K, VehicleRelationship>.favorite: DataClassPath<K, Boolean> get() = this[VehicleRelationship_favorite]
val <K> DataClassPath<K, VehicleRelationship>.notifications: DataClassPath<K, Boolean> get() = this[VehicleRelationship_notifications]
val <K> DataClassPath<K, VehicleRelationship>.autobid: DataClassPath<K, PriceInDollars?> get() = this[VehicleRelationship_autobid]
val <K> DataClassPath<K, VehicleRelationship>.autoBidsPlaced: DataClassPath<K, Int?> get() = this[VehicleRelationship_autoBidsPlaced]
val <K> DataClassPath<K, VehicleRelationship>.autobidLastActivated: DataClassPath<K, Instant> get() = this[VehicleRelationship_autobidLastActivated]
val <K> DataClassPath<K, VehicleRelationship>.autobidBeaten: DataClassPath<K, Instant?> get() = this[VehicleRelationship_autobidBeaten]
val <K> DataClassPath<K, VehicleRelationship>.personalNotes: DataClassPath<K, String> get() = this[VehicleRelationship_personalNotes]
inline val VehicleRelationship.Companion.path: DataClassPath<VehicleRelationship, VehicleRelationship> get() = path<VehicleRelationship>()


object VehicleRelationship__id: SerializableProperty<VehicleRelationship, UserVehiclePair> {
    override val name: String = "_id"
    override fun get(receiver: VehicleRelationship): UserVehiclePair = receiver._id
    override fun setCopy(receiver: VehicleRelationship, value: UserVehiclePair) = receiver.copy(_id = value)
    override val serializer: KSerializer<UserVehiclePair> = UserVehiclePair.serializer()
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("_id")
}
object VehicleRelationship_vehicleDenormalizedInfo: SerializableProperty<VehicleRelationship, ShortVehicle> {
    override val name: String = "vehicleDenormalizedInfo"
    override fun get(receiver: VehicleRelationship): ShortVehicle = receiver.vehicleDenormalizedInfo
    override fun setCopy(receiver: VehicleRelationship, value: ShortVehicle) = receiver.copy(vehicleDenormalizedInfo = value)
    override val serializer: KSerializer<ShortVehicle> = ShortVehicle.serializer()
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("vehicleDenormalizedInfo")
}
object VehicleRelationship_firstInteraction: SerializableProperty<VehicleRelationship, Instant> {
    override val name: String = "firstInteraction"
    override fun get(receiver: VehicleRelationship): Instant = receiver.firstInteraction
    override fun setCopy(receiver: VehicleRelationship, value: Instant) = receiver.copy(firstInteraction = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("firstInteraction")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_bidPlaced: SerializableProperty<VehicleRelationship, Instant?> {
    override val name: String = "bidPlaced"
    override fun get(receiver: VehicleRelationship): Instant? = receiver.bidPlaced
    override fun setCopy(receiver: VehicleRelationship, value: Instant?) = receiver.copy(bidPlaced = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("bidPlaced")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_favorite: SerializableProperty<VehicleRelationship, Boolean> {
    override val name: String = "favorite"
    override fun get(receiver: VehicleRelationship): Boolean = receiver.favorite
    override fun setCopy(receiver: VehicleRelationship, value: Boolean) = receiver.copy(favorite = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("favorite")
    override val default: Boolean = false // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_notifications: SerializableProperty<VehicleRelationship, Boolean> {
    override val name: String = "notifications"
    override fun get(receiver: VehicleRelationship): Boolean = receiver.notifications
    override fun setCopy(receiver: VehicleRelationship, value: Boolean) = receiver.copy(notifications = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("notifications")
    override val default: Boolean = false // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_autobid: SerializableProperty<VehicleRelationship, PriceInDollars?> {
    override val name: String = "autobid"
    override fun get(receiver: VehicleRelationship): PriceInDollars? = receiver.autobid
    override fun setCopy(receiver: VehicleRelationship, value: PriceInDollars?) = receiver.copy(autobid = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("autobid")
    override val default: PriceInDollars? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_autoBidsPlaced: SerializableProperty<VehicleRelationship, Int?> {
    override val name: String = "autoBidsPlaced"
    override fun get(receiver: VehicleRelationship): Int? = receiver.autoBidsPlaced
    override fun setCopy(receiver: VehicleRelationship, value: Int?) = receiver.copy(autoBidsPlaced = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("autoBidsPlaced")
    override val default: Int? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_autobidLastActivated: SerializableProperty<VehicleRelationship, Instant> {
    override val name: String = "autobidLastActivated"
    override fun get(receiver: VehicleRelationship): Instant = receiver.autobidLastActivated
    override fun setCopy(receiver: VehicleRelationship, value: Instant) = receiver.copy(autobidLastActivated = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("autobidLastActivated")
    override val default: Instant = Instant.fromEpochSeconds(0) // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_autobidBeaten: SerializableProperty<VehicleRelationship, Instant?> {
    override val name: String = "autobidBeaten"
    override fun get(receiver: VehicleRelationship): Instant? = receiver.autobidBeaten
    override fun setCopy(receiver: VehicleRelationship, value: Instant?) = receiver.copy(autobidBeaten = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("autobidBeaten")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
object VehicleRelationship_personalNotes: SerializableProperty<VehicleRelationship, String> {
    override val name: String = "personalNotes"
    override fun get(receiver: VehicleRelationship): String = receiver.personalNotes
    override fun setCopy(receiver: VehicleRelationship, value: String) = receiver.copy(personalNotes = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = VehicleRelationship.serializer().tryFindAnnotations("personalNotes")
    override val default: String = "" // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)vehicleDenormalizedInfo([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)firstInteraction([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bidPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)favorite([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)notifications([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobid([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autoBidsPlaced([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidLastActivated([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)autobidBeaten([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)personalNotes([^a-zA-Z0-9]|$)
}
