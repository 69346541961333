package com.ilussobsa

import com.lightningkite.kiteui.models.*

actual object Resources {
    actual val auctioneerBell: AudioResource = AudioResource("common/auctioneer/bell.mp3")
actual val auctioneerCrowdBackground: AudioResource = AudioResource("common/auctioneer/crowdBackground.mp3")
actual val auctioneerGavel: AudioResource = AudioResource("common/auctioneer/gavel.mp3")
actual val auctioneerManualBell: AudioResource = AudioResource("common/auctioneer/manual/bell.mp3")
actual val auctioneerManualHighbid: AudioResource = AudioResource("common/auctioneer/manual/highbid.mp3")
actual val auctioneerManualNext: AudioResource = AudioResource("common/auctioneer/manual/next.mp3")
actual val auctioneerManualNext2: AudioResource = AudioResource("common/auctioneer/manual/next2.mp3")
actual val auctioneerManualSold: AudioResource = AudioResource("common/auctioneer/manual/sold.mp3")
actual val auctioneerManualSold2: AudioResource = AudioResource("common/auctioneer/manual/sold2.mp3")
actual val auctioneerPlayhtAuctionEnd: AudioResource = AudioResource("common/auctioneer/playht/auctionEnd.mp3")
actual val auctioneerPlayhtDontLetThemWin: AudioResource = AudioResource("common/auctioneer/playht/dontLetThemWin.mp3")
actual val auctioneerPlayhtGoingOnce: AudioResource = AudioResource("common/auctioneer/playht/goingOnce.mp3")
actual val auctioneerPlayhtGoingTwice: AudioResource = AudioResource("common/auctioneer/playht/goingTwice.mp3")
actual val auctioneerPlayhtNext: AudioResource = AudioResource("common/auctioneer/playht/next.mp3")
actual val auctioneerPlayhtNoSale: AudioResource = AudioResource("common/auctioneer/playht/noSale.mp3")
actual val auctioneerPlayhtOfferReady: AudioResource = AudioResource("common/auctioneer/playht/offerReady.mp3")
actual val auctioneerPlayhtPriceDrop: AudioResource = AudioResource("common/auctioneer/playht/priceDrop.mp3")
actual val auctioneerPlayhtPriceDropHigh: AudioResource = AudioResource("common/auctioneer/playht/priceDropHigh.mp3")
actual val auctioneerPlayhtPriceDropLow: AudioResource = AudioResource("common/auctioneer/playht/priceDropLow.mp3")
actual val auctioneerPlayhtReserveMet: AudioResource = AudioResource("common/auctioneer/playht/reserveMet.mp3")
actual val auctioneerPlayhtSold: AudioResource = AudioResource("common/auctioneer/playht/sold.mp3")
actual val auctioneerPlayhtWelcome: AudioResource = AudioResource("common/auctioneer/playht/welcome.mp3")
actual val auctioneerPlayhtYouAreSoCloseDontGiveUpNow: AudioResource = AudioResource("common/auctioneer/playht/youAreSoCloseDontGiveUpNow.mp3")
actual val auctioneerPlayhtYouveComeToFarToStopNow: AudioResource = AudioResource("common/auctioneer/playht/youveComeToFarToStopNow.mp3")
actual val auctioneerProperBackflip: VideoResource = VideoResource("common/auctioneer/proper/Backflip.mp4")
actual val auctioneerProperDancing: VideoResource = VideoResource("common/auctioneer/proper/Dancing.mp4")
actual val auctioneerProperDieFromRight: VideoResource = VideoResource("common/auctioneer/proper/Die from Right.mp4")
actual val auctioneerProperIdleStart: VideoResource = VideoResource("common/auctioneer/proper/Idle start.mp4")
actual val auctioneerProperOfferReadyCall: VideoResource = VideoResource("common/auctioneer/proper/Offer Ready Call.mp4")
actual val auctioneerProperSoldPoint: VideoResource = VideoResource("common/auctioneer/proper/Sold Point.mp4")
actual val auctioneerRecording1BottomPriceCheap1: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/cheap.1.mp3")
actual val auctioneerRecording1BottomPriceCheap2: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/cheap.2.mp3")
actual val auctioneerRecording1BottomPriceCheap3: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/cheap.3.mp3")
actual val auctioneerRecording1BottomPriceWhosFirst1: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/whos_first.1.mp3")
actual val auctioneerRecording1BottomPriceWhosFirst2: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/whos_first.2.mp3")
actual val auctioneerRecording1BottomPriceWhosFirst3: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/whos_first.3.mp3")
actual val auctioneerRecording1BottomPriceWhosFirst4: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/whos_first.4.mp3")
actual val auctioneerRecording1BottomPriceWhosFirst5: AudioResource = AudioResource("common/auctioneer/recording1/Bottom Price/whos_first.5.mp3")
actual val auctioneerRecording1EncouragementEncourage1: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.1.mp3")
actual val auctioneerRecording1EncouragementEncourage2: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.2.mp3")
actual val auctioneerRecording1EncouragementEncourage3: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.3.mp3")
actual val auctioneerRecording1EncouragementEncourage4: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.4.mp3")
actual val auctioneerRecording1EncouragementEncourage5: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.5.mp3")
actual val auctioneerRecording1EncouragementEncourage6: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.6.mp3")
actual val auctioneerRecording1EncouragementEncourage7: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.7.mp3")
actual val auctioneerRecording1EncouragementEncourage8: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.8.mp3")
actual val auctioneerRecording1EncouragementEncourage9: AudioResource = AudioResource("common/auctioneer/recording1/Encouragement/encourage.9.mp3")
actual val auctioneerRecording1FinalSayingFinalSaying1: AudioResource = AudioResource("common/auctioneer/recording1/Final Saying/final_saying.1.mp3")
actual val auctioneerRecording1FinalSayingFinalSaying2: AudioResource = AudioResource("common/auctioneer/recording1/Final Saying/final_saying.2.mp3")
actual val auctioneerRecording1FinalSayingFinalSaying3: AudioResource = AudioResource("common/auctioneer/recording1/Final Saying/final_saying.3.mp3")
actual val auctioneerRecording1FirstBidFirstBid1: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.1.mp3")
actual val auctioneerRecording1FirstBidFirstBid10: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.10.mp3")
actual val auctioneerRecording1FirstBidFirstBid11: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.11.mp3")
actual val auctioneerRecording1FirstBidFirstBid12: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.12.mp3")
actual val auctioneerRecording1FirstBidFirstBid13: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.13.mp3")
actual val auctioneerRecording1FirstBidFirstBid14: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.14.mp3")
actual val auctioneerRecording1FirstBidFirstBid2: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.2.mp3")
actual val auctioneerRecording1FirstBidFirstBid3: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.3.mp3")
actual val auctioneerRecording1FirstBidFirstBid4: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.4.mp3")
actual val auctioneerRecording1FirstBidFirstBid5: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.5.mp3")
actual val auctioneerRecording1FirstBidFirstBid6: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.6.mp3")
actual val auctioneerRecording1FirstBidFirstBid7: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.7.mp3")
actual val auctioneerRecording1FirstBidFirstBid8: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.8.mp3")
actual val auctioneerRecording1FirstBidFirstBid9: AudioResource = AudioResource("common/auctioneer/recording1/First Bid/first_bid.9.mp3")
actual val auctioneerRecording1IntroIntro1: AudioResource = AudioResource("common/auctioneer/recording1/Intro/intro.1.mp3")
actual val auctioneerRecording1IntroIntro2: AudioResource = AudioResource("common/auctioneer/recording1/Intro/intro.2.mp3")
actual val auctioneerRecording1IntroIntro3: AudioResource = AudioResource("common/auctioneer/recording1/Intro/intro.3.mp3")
actual val auctioneerRecording1NextNextUp1: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.1.mp3")
actual val auctioneerRecording1NextNextUp10: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.10.mp3")
actual val auctioneerRecording1NextNextUp2: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.2.mp3")
actual val auctioneerRecording1NextNextUp3: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.3.mp3")
actual val auctioneerRecording1NextNextUp4: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.4.mp3")
actual val auctioneerRecording1NextNextUp5: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.5.mp3")
actual val auctioneerRecording1NextNextUp6: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.6.mp3")
actual val auctioneerRecording1NextNextUp7: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.7.mp3")
actual val auctioneerRecording1NextNextUp8: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.8.wav")
actual val auctioneerRecording1NextNextUp9: AudioResource = AudioResource("common/auctioneer/recording1/Next/next_up.9.wav")
actual val auctioneerRecording1NextOnTheMoney1: AudioResource = AudioResource("common/auctioneer/recording1/Next/on_the_money.1.mp3")
actual val auctioneerRecording1NextOnTheMoney2: AudioResource = AudioResource("common/auctioneer/recording1/Next/on_the_money.2.mp3")
actual val auctioneerRecording1NextOnTheMoney3: AudioResource = AudioResource("common/auctioneer/recording1/Next/on_the_money.3.mp3")
actual val auctioneerRecording1NextOnTheMoney4: AudioResource = AudioResource("common/auctioneer/recording1/Next/on_the_money.4.mp3")
actual val auctioneerRecording1PriceDropPricedrop1: AudioResource = AudioResource("common/auctioneer/recording1/Price Drop/pricedrop.1.mp3")
actual val auctioneerRecording1PriceDropPricedrop2: AudioResource = AudioResource("common/auctioneer/recording1/Price Drop/pricedrop.2.mp3")
actual val auctioneerRecording1PriceDropPricedrop3: AudioResource = AudioResource("common/auctioneer/recording1/Price Drop/pricedrop.3.mp3")
actual val auctioneerRecording1PriceDropPricedrop4: AudioResource = AudioResource("common/auctioneer/recording1/Price Drop/pricedrop.4.mp3")
actual val auctioneerRecording1ReserveMetReserve1: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.1.mp3")
actual val auctioneerRecording1ReserveMetReserve10: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.10.mp3")
actual val auctioneerRecording1ReserveMetReserve11: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.11.mp3")
actual val auctioneerRecording1ReserveMetReserve12: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.12.mp3")
actual val auctioneerRecording1ReserveMetReserve13: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.13.mp3")
actual val auctioneerRecording1ReserveMetReserve14: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.14.mp3")
actual val auctioneerRecording1ReserveMetReserve2: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.2.mp3")
actual val auctioneerRecording1ReserveMetReserve3: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.3.mp3")
actual val auctioneerRecording1ReserveMetReserve4: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.4.mp3")
actual val auctioneerRecording1ReserveMetReserve5: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.5.mp3")
actual val auctioneerRecording1ReserveMetReserve6: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.6.mp3")
actual val auctioneerRecording1ReserveMetReserve7: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.7.mp3")
actual val auctioneerRecording1ReserveMetReserve8: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.8.mp3")
actual val auctioneerRecording1ReserveMetReserve9: AudioResource = AudioResource("common/auctioneer/recording1/Reserve Met/reserve.9.mp3")
actual val auctioneerRecording1SoldGoingonce1: AudioResource = AudioResource("common/auctioneer/recording1/Sold/goingonce.1.mp3")
actual val auctioneerRecording1SoldGoingonce2: AudioResource = AudioResource("common/auctioneer/recording1/Sold/goingonce.2.mp3")
actual val auctioneerRecording1SoldGoingonce3: AudioResource = AudioResource("common/auctioneer/recording1/Sold/goingonce.3.mp3")
actual val auctioneerRecording1SoldGoingtwice4: AudioResource = AudioResource("common/auctioneer/recording1/Sold/goingtwice.4.mp3")
actual val auctioneerRecording1SoldGoingtwice5: AudioResource = AudioResource("common/auctioneer/recording1/Sold/goingtwice.5.mp3")
actual val auctioneerRecording1SoldGoingtwice6: AudioResource = AudioResource("common/auctioneer/recording1/Sold/goingtwice.6.mp3")
actual val auctioneerRecording1SoldOfferReady1: AudioResource = AudioResource("common/auctioneer/recording1/Sold/offer_ready.1.mp3")
actual val auctioneerRecording1SoldOfferReady2: AudioResource = AudioResource("common/auctioneer/recording1/Sold/offer_ready.2.mp3")
actual val auctioneerRecording1SoldOfferReady3: AudioResource = AudioResource("common/auctioneer/recording1/Sold/offer_ready.3.mp3")
actual val auctioneerRecording1SoldSold7: AudioResource = AudioResource("common/auctioneer/recording1/Sold/sold.7.mp3")
actual val auctioneerRecording1SoldSold8: AudioResource = AudioResource("common/auctioneer/recording1/Sold/sold.8.mp3")
actual val auctioneerRecording1SoldSold9: AudioResource = AudioResource("common/auctioneer/recording1/Sold/sold.9.mp3")
actual val auctioneerRingmenYell: AudioResource = AudioResource("common/auctioneer/ringmenYell.mp3")
actual val auctioneerRingmenYell2: AudioResource = AudioResource("common/auctioneer/ringmenYell2.mp3")
actual val auctioneerSpeechloAuctionEnd: AudioResource = AudioResource("common/auctioneer/speechlo/auctionEnd.mp3")
actual val auctioneerSpeechloDontLetThemWin: AudioResource = AudioResource("common/auctioneer/speechlo/dontLetThemWin.mp3")
actual val auctioneerSpeechloGoingOnce: AudioResource = AudioResource("common/auctioneer/speechlo/goingOnce.mp3")
actual val auctioneerSpeechloGoingTwice: AudioResource = AudioResource("common/auctioneer/speechlo/goingTwice.mp3")
actual val auctioneerSpeechloNext: AudioResource = AudioResource("common/auctioneer/speechlo/next.mp3")
actual val auctioneerSpeechloNoSale: AudioResource = AudioResource("common/auctioneer/speechlo/noSale.mp3")
actual val auctioneerSpeechloOfferReady: AudioResource = AudioResource("common/auctioneer/speechlo/offerReady.mp3")
actual val auctioneerSpeechloPriceDrop: AudioResource = AudioResource("common/auctioneer/speechlo/priceDrop.mp3")
actual val auctioneerSpeechloReserveMet: AudioResource = AudioResource("common/auctioneer/speechlo/reserveMet.mp3")
actual val auctioneerSpeechloSold: AudioResource = AudioResource("common/auctioneer/speechlo/sold.mp3")
actual val auctioneerSpeechloWelcome: AudioResource = AudioResource("common/auctioneer/speechlo/welcome.mp3")
actual val auctioneerSpeechloYouAreSoCloseDontGiveUpNow: AudioResource = AudioResource("common/auctioneer/speechlo/youAreSoCloseDontGiveUpNow.mp3")
actual val auctioneerSpeechloYouveComeToFarToStopNow: AudioResource = AudioResource("common/auctioneer/speechlo/youveComeToFarToStopNow.mp3")
actual val auctioneerYepYep1: AudioResource = AudioResource("common/auctioneer/yep/yep1.mp3")
actual val auctioneerYepYep10: AudioResource = AudioResource("common/auctioneer/yep/yep10.mp3")
actual val auctioneerYepYep11: AudioResource = AudioResource("common/auctioneer/yep/yep11.mp3")
actual val auctioneerYepYep12: AudioResource = AudioResource("common/auctioneer/yep/yep12.mp3")
actual val auctioneerYepYep2: AudioResource = AudioResource("common/auctioneer/yep/yep2.mp3")
actual val auctioneerYepYep3: AudioResource = AudioResource("common/auctioneer/yep/yep3.mp3")
actual val auctioneerYepYep4: AudioResource = AudioResource("common/auctioneer/yep/yep4.mp3")
actual val auctioneerYepYep5: AudioResource = AudioResource("common/auctioneer/yep/yep5.mp3")
actual val auctioneerYepYep6: AudioResource = AudioResource("common/auctioneer/yep/yep6.mp3")
actual val auctioneerYepYep7: AudioResource = AudioResource("common/auctioneer/yep/yep7.mp3")
actual val auctioneerYepYep8: AudioResource = AudioResource("common/auctioneer/yep/yep8.mp3")
actual val auctioneerYepYep9: AudioResource = AudioResource("common/auctioneer/yep/yep9.mp3")
actual val blinker: Font = Font(cssFontFamilyName = "blinker", direct = FontDirect(normal = mapOf(400 to "common/blinker/normal.ttf"), italics = mapOf()))
actual val goldman: Font = Font(cssFontFamilyName = "goldman", direct = FontDirect(normal = mapOf(700 to "common/goldman/bold.ttf", 400 to "common/goldman/normal.ttf"), italics = mapOf()))
actual val loginBackVideo: VideoResource = VideoResource("common/loginBackVideo.mp4")
actual val montserrat: Font = Font(cssFontFamilyName = "montserrat", direct = FontDirect(normal = mapOf(700 to "common/montserrat/bold.ttf", 400 to "common/montserrat/normal.ttf"), italics = mapOf(400 to "common/montserrat/italic.ttf", 700 to "common/montserrat/bold-italic.ttf")))
actual val opensans: Font = Font(cssFontFamilyName = "opensans", direct = FontDirect(normal = mapOf(700 to "common/opensans/bold.ttf", 400 to "common/opensans/normal.ttf"), italics = mapOf(400 to "common/opensans/italic.ttf", 700 to "common/opensans/bold-italic.ttf")))
actual val placeholder: ImageResource = ImageResource("common/placeholder.png")
actual val placeholdersAngled: ImageResource = ImageResource("common/placeholders/angled.jpg")
actual val placeholdersDash: ImageResource = ImageResource("common/placeholders/dash.jpg")
actual val placeholdersInterior: ImageResource = ImageResource("common/placeholders/interior.jpg")
actual val placeholdersVin: ImageResource = ImageResource("common/placeholders/vin.jpg")
actual val poppins: Font = Font(cssFontFamilyName = "poppins", direct = FontDirect(normal = mapOf(700 to "common/poppins/bold.ttf", 400 to "common/poppins/normal.ttf"), italics = mapOf(400 to "common/poppins/italic.ttf", 700 to "common/poppins/bold-italic.ttf")))
actual val roboto: Font = Font(cssFontFamilyName = "roboto", direct = FontDirect(normal = mapOf(300 to "common/roboto/light.ttf", 700 to "common/roboto/bold.ttf", 400 to "common/roboto/normal.ttf"), italics = mapOf(400 to "common/roboto/italic.ttf", 300 to "common/roboto/light-Italic.ttf", 700 to "common/roboto/bold-italic.ttf")))
actual val russo: Font = Font(cssFontFamilyName = "russo", direct = FontDirect(normal = mapOf(400 to "common/russo.ttf"), italics = mapOf()))
actual val stolzl: Font = Font(cssFontFamilyName = "stolzl", direct = FontDirect(normal = mapOf(400 to "common/stolzl/normal.otf"), italics = mapOf()))
actual val teko: Font = Font(cssFontFamilyName = "teko", direct = FontDirect(normal = mapOf(700 to "common/teko/bold.ttf", 400 to "common/teko/normal.ttf"), italics = mapOf()))
actual val tutorialsBuyingImage1: ImageResource = ImageResource("common/tutorials/buying/image1.png")
actual val tutorialsBuyingImage10: ImageResource = ImageResource("common/tutorials/buying/image10.png")
actual val tutorialsBuyingImage11: ImageResource = ImageResource("common/tutorials/buying/image11.png")
actual val tutorialsBuyingImage12: ImageResource = ImageResource("common/tutorials/buying/image12.png")
actual val tutorialsBuyingImage13: ImageResource = ImageResource("common/tutorials/buying/image13.png")
actual val tutorialsBuyingImage14: ImageResource = ImageResource("common/tutorials/buying/image14.png")
actual val tutorialsBuyingImage15: ImageResource = ImageResource("common/tutorials/buying/image15.png")
actual val tutorialsBuyingImage16: ImageResource = ImageResource("common/tutorials/buying/image16.png")
actual val tutorialsBuyingImage17: ImageResource = ImageResource("common/tutorials/buying/image17.png")
actual val tutorialsBuyingImage18: ImageResource = ImageResource("common/tutorials/buying/image18.png")
actual val tutorialsBuyingImage19: ImageResource = ImageResource("common/tutorials/buying/image19.png")
actual val tutorialsBuyingImage2: ImageResource = ImageResource("common/tutorials/buying/image2.png")
actual val tutorialsBuyingImage20: ImageResource = ImageResource("common/tutorials/buying/image20.png")
actual val tutorialsBuyingImage21: ImageResource = ImageResource("common/tutorials/buying/image21.png")
actual val tutorialsBuyingImage3: ImageResource = ImageResource("common/tutorials/buying/image3.png")
actual val tutorialsBuyingImage4: ImageResource = ImageResource("common/tutorials/buying/image4.png")
actual val tutorialsBuyingImage5: ImageResource = ImageResource("common/tutorials/buying/image5.png")
actual val tutorialsBuyingImage6: ImageResource = ImageResource("common/tutorials/buying/image6.png")
actual val tutorialsBuyingImage7: ImageResource = ImageResource("common/tutorials/buying/image7.png")
actual val tutorialsBuyingImage8: ImageResource = ImageResource("common/tutorials/buying/image8.png")
actual val tutorialsBuyingImage9: ImageResource = ImageResource("common/tutorials/buying/image9.png")
actual val tutorialsCompletingImage1: ImageResource = ImageResource("common/tutorials/completing/image1.png")
actual val tutorialsCompletingImage10: ImageResource = ImageResource("common/tutorials/completing/image10.png")
actual val tutorialsCompletingImage11: ImageResource = ImageResource("common/tutorials/completing/image11.png")
actual val tutorialsCompletingImage2: ImageResource = ImageResource("common/tutorials/completing/image2.png")
actual val tutorialsCompletingImage3: ImageResource = ImageResource("common/tutorials/completing/image3.png")
actual val tutorialsCompletingImage4: ImageResource = ImageResource("common/tutorials/completing/image4.png")
actual val tutorialsCompletingImage5: ImageResource = ImageResource("common/tutorials/completing/image5.png")
actual val tutorialsCompletingImage6: ImageResource = ImageResource("common/tutorials/completing/image6.png")
actual val tutorialsCompletingImage7: ImageResource = ImageResource("common/tutorials/completing/image7.png")
actual val tutorialsCompletingImage8: ImageResource = ImageResource("common/tutorials/completing/image8.png")
actual val tutorialsCompletingImage9: ImageResource = ImageResource("common/tutorials/completing/image9.png")
actual val tutorialsSellingImage1: ImageResource = ImageResource("common/tutorials/selling/image1.png")
actual val tutorialsSellingImage10: ImageResource = ImageResource("common/tutorials/selling/image10.png")
actual val tutorialsSellingImage11: ImageResource = ImageResource("common/tutorials/selling/image11.png")
actual val tutorialsSellingImage12: ImageResource = ImageResource("common/tutorials/selling/image12.png")
actual val tutorialsSellingImage13: ImageResource = ImageResource("common/tutorials/selling/image13.png")
actual val tutorialsSellingImage14: ImageResource = ImageResource("common/tutorials/selling/image14.png")
actual val tutorialsSellingImage15: ImageResource = ImageResource("common/tutorials/selling/image15.png")
actual val tutorialsSellingImage16: ImageResource = ImageResource("common/tutorials/selling/image16.png")
actual val tutorialsSellingImage17: ImageResource = ImageResource("common/tutorials/selling/image17.png")
actual val tutorialsSellingImage18: ImageResource = ImageResource("common/tutorials/selling/image18.png")
actual val tutorialsSellingImage19: ImageResource = ImageResource("common/tutorials/selling/image19.png")
actual val tutorialsSellingImage2: ImageResource = ImageResource("common/tutorials/selling/image2.png")
actual val tutorialsSellingImage20: ImageResource = ImageResource("common/tutorials/selling/image20.png")
actual val tutorialsSellingImage21: ImageResource = ImageResource("common/tutorials/selling/image21.png")
actual val tutorialsSellingImage22: ImageResource = ImageResource("common/tutorials/selling/image22.png")
actual val tutorialsSellingImage23: ImageResource = ImageResource("common/tutorials/selling/image23.png")
actual val tutorialsSellingImage24: ImageResource = ImageResource("common/tutorials/selling/image24.png")
actual val tutorialsSellingImage3: ImageResource = ImageResource("common/tutorials/selling/image3.png")
actual val tutorialsSellingImage4: ImageResource = ImageResource("common/tutorials/selling/image4.png")
actual val tutorialsSellingImage5: ImageResource = ImageResource("common/tutorials/selling/image5.png")
actual val tutorialsSellingImage6: ImageResource = ImageResource("common/tutorials/selling/image6.png")
actual val tutorialsSellingImage7: ImageResource = ImageResource("common/tutorials/selling/image7.png")
actual val tutorialsSellingImage8: ImageResource = ImageResource("common/tutorials/selling/image8.png")
actual val tutorialsSellingImage9: ImageResource = ImageResource("common/tutorials/selling/image9.png")
actual val tutorialsStartingImage1: ImageResource = ImageResource("common/tutorials/starting/image1.png")
actual val tutorialsStartingImage2: ImageResource = ImageResource("common/tutorials/starting/image2.png")
actual val tutorialsStartingImage3: ImageResource = ImageResource("common/tutorials/starting/image3.png")
actual val tutorialsStartingImage4: ImageResource = ImageResource("common/tutorials/starting/image4.png")
actual val tutorialsStartingImage5: ImageResource = ImageResource("common/tutorials/starting/image5.png")
actual val tutorialsStartingImage6: ImageResource = ImageResource("common/tutorials/starting/image6.png")
actual val tutorialsStartingImage7: ImageResource = ImageResource("common/tutorials/starting/image7.png")
}