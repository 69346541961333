package com.ilussobsa.views.dialogs

import com.ilussobsa.Strings
import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.QueryParameter
import com.lightningkite.kiteui.models.Align
import com.lightningkite.kiteui.models.KeyboardHints
import com.lightningkite.kiteui.models.SizeConstraints
import com.lightningkite.kiteui.models.rem
import com.lightningkite.kiteui.navigation.Screen
import com.lightningkite.kiteui.navigation.dialogScreenNavigator
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.report
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*


class SendEmailDialog(val onConfirm: (suspend (String) -> Unit)) : Screen {

    val message = Property<String>("")
    override fun ViewWriter.render() {
        dismissBackground {
            centered - sizedBox(SizeConstraints(maxWidth = 40.rem, minWidth = 20.rem)) - card - col {
                text {
                    align = Align.Center
                    content = "Ask us a Question"
                }
                card - textArea {
                    keyboardHints = KeyboardHints.paragraph
                    hint = "Type your vehicle inquiry here..."

                    content bind message
                }
                row {
                    card - button {
                        text {
                            align = Align.Center
                            content = Strings.cancel
                        }
                        onClick {
                            dialogScreenNavigator.dismiss()
                        }
                    }
                    expanding - space()
                    card - button {
                        text {
                            align = Align.Center
                            content = "Send Inquiry Email"
                        }
                        onClick {
                            onConfirm.invoke(message())
                            dialogScreenNavigator.dismiss()
                        }
                    }
                }
            }
        }
    }
}