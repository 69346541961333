package com.ilussobsa

import com.lightningkite.serialization.*
import kotlinx.serialization.builtins.NothingSerializer

fun prepareModelsShared() { SharedModelsObject }
object SharedModelsObject {
    init {
        SerializationRegistry.master.registerShared()
        com.ilussobsa.prepareUserFields()
        com.ilussobsa.prepareExternalFinancingFormFields()
        com.ilussobsa.prepareNotificationTypeSettingsFields()
        com.ilussobsa.prepareFcmTokenFields()
        com.ilussobsa.prepareShortVehicleFields()
        com.ilussobsa.prepareVehicleRelationshipFields()
        com.ilussobsa.prepareUserVehiclePairFields()
        com.ilussobsa.prepareVehicleSearchFields()
        com.ilussobsa.prepareCompletionFields()
        com.ilussobsa.prepareLiveAuctionDataFields()
        com.ilussobsa.prepareSellerTalkingPointFields()
        com.ilussobsa.prepareVehicleFields()
        com.ilussobsa.prepareAttachmentFields()
        com.ilussobsa.prepareVehicleMediaFields()
        com.ilussobsa.prepareVehiclePhotoFields()
        com.ilussobsa.prepareExtraInfoFields()
        com.ilussobsa.prepareDamageFields()
        com.ilussobsa.prepareTransportAddressFields()
        com.ilussobsa.prepareAddressFields()
        com.ilussobsa.preparePurchasedInfoFields()
        com.ilussobsa.prepareDealershipGroupFields()
        com.ilussobsa.prepareMakeFields()
        com.ilussobsa.prepareModelFields()
        com.ilussobsa.prepareSavedSearchFields()
        com.ilussobsa.prepareAuctionLaneFields()
        com.ilussobsa.prepareBidFields()
        com.ilussobsa.prepareNotificationFields()
        com.ilussobsa.prepareStripeWebhookSecretFields()
        com.ilussobsa.prepareItemFields()
        com.ilussobsa.prepareEarnestPaymentFields()
        com.ilussobsa.prepareFinancingApplicationFields()
        com.ilussobsa.prepareFinancingApplicantFields()
        com.ilussobsa.prepareResidencyInfoFields()
        com.ilussobsa.prepareEmployerInfoFields()
        com.ilussobsa.prepareUsAddressFields()
        com.ilussobsa.preparePaymentMethodFields()
    }
}

fun SerializationRegistry.registerShared() {
    register(com.ilussobsa.ResetDataRequest.serializer())
    register(com.ilussobsa.RoundingLevel.serializer())
    register(com.ilussobsa.UserPartial.serializer())
    register(com.ilussobsa.User.serializer())
    register(com.ilussobsa.FinancingSource.serializer())
    register(com.ilussobsa.ExternalFinancingForm.serializer())
    register(com.ilussobsa.NotificationTypeSettings.serializer())
    register(com.ilussobsa.UserRole.serializer())
    register(com.ilussobsa.FcmToken.serializer())
    register(com.ilussobsa.ShortVehicle.serializer())
    register(com.ilussobsa.VehicleRelationship.serializer())
    register(com.ilussobsa.UserVehiclePair.serializer())
    register(com.ilussobsa.VehicleSearch.serializer())
    register(com.ilussobsa.Completion.serializer())
    register(com.ilussobsa.CancellationReason.serializer())
    register(com.ilussobsa.LiveAuctionData.serializer())
    register(com.ilussobsa.SellerTalkingPoint.serializer())
    register(com.ilussobsa.Auctioneer.serializer())
    register(com.ilussobsa.AnimationAction.serializer())
    register(com.ilussobsa.Vehicle.serializer())
    register(com.ilussobsa.Attachment.serializer())
    register(com.ilussobsa.VehicleMedia.serializer())
    register(com.ilussobsa.VehiclePhoto.serializer())
    register(com.ilussobsa.ExtraInfo.serializer())
    register(com.ilussobsa.Severity.serializer())
    register(com.ilussobsa.TireStatus.serializer())
    register(com.ilussobsa.KeyCount.serializer())
    register(com.ilussobsa.ReconditioningStatus.serializer())
    register(com.ilussobsa.Damage.serializer())
    register(com.ilussobsa.Transmission.serializer())
    register(com.ilussobsa.FuelType.serializer())
    register(com.ilussobsa.TransportAddress.serializer())
    register(com.ilussobsa.Address.serializer())
    register(com.ilussobsa.PurchasedInfo.serializer())
    register(com.ilussobsa.DealershipGroup.serializer())
    register(com.ilussobsa.Make.serializer())
    register(com.ilussobsa.Model.serializer())
    register(com.ilussobsa.Grade.serializer())
    register(com.ilussobsa.SearchParamsOnly.serializer())
    register(com.ilussobsa.SavedSearch.serializer())
    register(com.ilussobsa.AuctionLane.serializer())
    register(com.ilussobsa.Bid.serializer())
    register(com.ilussobsa.Notification.serializer())
    register(com.ilussobsa.NotificationUserType.serializer())
    register(com.ilussobsa.NotificationTopic.serializer())
    register(com.ilussobsa.UsState.serializer())
    register(com.ilussobsa.StripeWebhookSecret.serializer())
    register(com.ilussobsa.Item.serializer())
    register(com.ilussobsa.EarnestPayment.serializer())
    register(com.ilussobsa.FinancingApplication.serializer())
    register(com.ilussobsa.FinancingApplicant.serializer())
    register(com.ilussobsa.ResidencyInfo.serializer())
    register(com.ilussobsa.EmployerInfo.serializer())
    register(com.ilussobsa.UsAddress.serializer())
    register(com.ilussobsa.ResidenceType.serializer())
    register(com.ilussobsa.VehicleInquiry.serializer())
    register(com.ilussobsa.BankAccountType.serializer())
    register(com.ilussobsa.BankAccountHolderType.serializer())
    register(com.ilussobsa.Bank.serializer())
    register(com.ilussobsa.PaymentMethod.serializer())
    register(com.ilussobsa.MicroDepositVerification.serializer())
    register(com.ilussobsa.PendingAction.serializer())
    register(com.ilussobsa.PendingPaymentMethod.serializer())
    register(com.ilussobsa.PaymentRequest.serializer())
    register(com.ilussobsa.Payment.serializer())
    register(com.ilussobsa.PaymentStatus.serializer())
}
