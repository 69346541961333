// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareDealershipGroupFields() {
    val props: Array<SerializableProperty<DealershipGroup, *>> = arrayOf(DealershipGroup__id, DealershipGroup_name)
    DealershipGroup.serializer().properties { props }
}
val <K> DataClassPath<K, DealershipGroup>._id: DataClassPath<K, UUID> get() = this[DealershipGroup__id]
val <K> DataClassPath<K, DealershipGroup>.name: DataClassPath<K, String> get() = this[DealershipGroup_name]
inline val DealershipGroup.Companion.path: DataClassPath<DealershipGroup, DealershipGroup> get() = path<DealershipGroup>()


object DealershipGroup__id: SerializableProperty<DealershipGroup, UUID> {
    override val name: String = "_id"
    override fun get(receiver: DealershipGroup): UUID = receiver._id
    override fun setCopy(receiver: DealershipGroup, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = DealershipGroup.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)name([^a-zA-Z0-9]|$)
}
object DealershipGroup_name: SerializableProperty<DealershipGroup, String> {
    override val name: String = "name"
    override fun get(receiver: DealershipGroup): String = receiver.name
    override fun setCopy(receiver: DealershipGroup, value: String) = receiver.copy(name = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = DealershipGroup.serializer().tryFindAnnotations("name")
}
