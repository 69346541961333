// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareFcmTokenFields() {
    val props: Array<SerializableProperty<FcmToken, *>> = arrayOf(FcmToken__id, FcmToken_user, FcmToken_active, FcmToken_created)
    FcmToken.serializer().properties { props }
}
val <K> DataClassPath<K, FcmToken>._id: DataClassPath<K, String> get() = this[FcmToken__id]
val <K> DataClassPath<K, FcmToken>.user: DataClassPath<K, UUID> get() = this[FcmToken_user]
val <K> DataClassPath<K, FcmToken>.active: DataClassPath<K, Boolean> get() = this[FcmToken_active]
val <K> DataClassPath<K, FcmToken>.created: DataClassPath<K, Instant> get() = this[FcmToken_created]
inline val FcmToken.Companion.path: DataClassPath<FcmToken, FcmToken> get() = path<FcmToken>()


object FcmToken__id: SerializableProperty<FcmToken, String> {
    override val name: String = "_id"
    override fun get(receiver: FcmToken): String = receiver._id
    override fun setCopy(receiver: FcmToken, value: String) = receiver.copy(_id = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = FcmToken.serializer().tryFindAnnotations("_id")
}
object FcmToken_user: SerializableProperty<FcmToken, UUID> {
    override val name: String = "user"
    override fun get(receiver: FcmToken): UUID = receiver.user
    override fun setCopy(receiver: FcmToken, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = FcmToken.serializer().tryFindAnnotations("user")
}
object FcmToken_active: SerializableProperty<FcmToken, Boolean> {
    override val name: String = "active"
    override fun get(receiver: FcmToken): Boolean = receiver.active
    override fun setCopy(receiver: FcmToken, value: Boolean) = receiver.copy(active = value)
    override val serializer: KSerializer<Boolean> = Boolean.serializer()
    override val annotations: List<Annotation> = FcmToken.serializer().tryFindAnnotations("active")
    override val default: Boolean = true // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)active([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)created([^a-zA-Z0-9]|$)
}
object FcmToken_created: SerializableProperty<FcmToken, Instant> {
    override val name: String = "created"
    override fun get(receiver: FcmToken): Instant = receiver.created
    override fun setCopy(receiver: FcmToken, value: Instant) = receiver.copy(created = value)
    override val serializer: KSerializer<Instant> = ContextualSerializer(Instant::class)
    override val annotations: List<Annotation> = FcmToken.serializer().tryFindAnnotations("created")
    override val default: Instant = now() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)active([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)created([^a-zA-Z0-9]|$)
}
