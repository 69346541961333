// Automatically generated based off models.kt
@file:OptIn(ExperimentalSerializationApi::class, InternalSerializationApi::class)
@file:Suppress("UnusedImport")

package com.ilussobsa

import com.lightningkite.*
import com.lightningkite.Length.Companion.miles
import com.lightningkite.lightningdb.*
import com.lightningkite.lightningserver.files.*
import com.lightningkite.serialization.*
import kotlin.jvm.JvmInline
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds
import kotlinx.datetime.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseContextualSerialization
import com.lightningkite.serialization.DataClassPath
import com.lightningkite.serialization.DataClassPathSelf
import com.lightningkite.serialization.SerializableProperty
import kotlin.reflect.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.*
import kotlinx.serialization.internal.GeneratedSerializer

// Contextual types: kotlinx.datetime.Instant, com.lightningkite.UUID, com.lightningkite.lightningserver.files.ServerFile, kotlinx.datetime.LocalDate
fun prepareExternalFinancingFormFields() {
    val props: Array<SerializableProperty<ExternalFinancingForm, *>> = arrayOf(ExternalFinancingForm__id, ExternalFinancingForm_user, ExternalFinancingForm_bankName, ExternalFinancingForm_approvalAmount, ExternalFinancingForm_termLengthInMonths, ExternalFinancingForm_interestRate, ExternalFinancingForm_submittedAt)
    ExternalFinancingForm.serializer().properties { props }
}
val <K> DataClassPath<K, ExternalFinancingForm>._id: DataClassPath<K, UUID> get() = this[ExternalFinancingForm__id]
val <K> DataClassPath<K, ExternalFinancingForm>.user: DataClassPath<K, UUID> get() = this[ExternalFinancingForm_user]
val <K> DataClassPath<K, ExternalFinancingForm>.bankName: DataClassPath<K, String> get() = this[ExternalFinancingForm_bankName]
val <K> DataClassPath<K, ExternalFinancingForm>.approvalAmount: DataClassPath<K, PriceInDollars?> get() = this[ExternalFinancingForm_approvalAmount]
val <K> DataClassPath<K, ExternalFinancingForm>.termLengthInMonths: DataClassPath<K, Int?> get() = this[ExternalFinancingForm_termLengthInMonths]
val <K> DataClassPath<K, ExternalFinancingForm>.interestRate: DataClassPath<K, Double?> get() = this[ExternalFinancingForm_interestRate]
val <K> DataClassPath<K, ExternalFinancingForm>.submittedAt: DataClassPath<K, Instant?> get() = this[ExternalFinancingForm_submittedAt]
inline val ExternalFinancingForm.Companion.path: DataClassPath<ExternalFinancingForm, ExternalFinancingForm> get() = path<ExternalFinancingForm>()


object ExternalFinancingForm__id: SerializableProperty<ExternalFinancingForm, UUID> {
    override val name: String = "_id"
    override fun get(receiver: ExternalFinancingForm): UUID = receiver._id
    override fun setCopy(receiver: ExternalFinancingForm, value: UUID) = receiver.copy(_id = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ExternalFinancingForm.serializer().tryFindAnnotations("_id")
    override val default: UUID = uuid() // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bankName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)approvalAmount([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)termLengthInMonths([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)interestRate([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)submittedAt([^a-zA-Z0-9]|$)
}
object ExternalFinancingForm_user: SerializableProperty<ExternalFinancingForm, UUID> {
    override val name: String = "user"
    override fun get(receiver: ExternalFinancingForm): UUID = receiver.user
    override fun setCopy(receiver: ExternalFinancingForm, value: UUID) = receiver.copy(user = value)
    override val serializer: KSerializer<UUID> = ContextualSerializer(UUID::class)
    override val annotations: List<Annotation> = ExternalFinancingForm.serializer().tryFindAnnotations("user")
    override val default: UUID = nullUuid // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bankName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)approvalAmount([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)termLengthInMonths([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)interestRate([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)submittedAt([^a-zA-Z0-9]|$)
}
object ExternalFinancingForm_bankName: SerializableProperty<ExternalFinancingForm, String> {
    override val name: String = "bankName"
    override fun get(receiver: ExternalFinancingForm): String = receiver.bankName
    override fun setCopy(receiver: ExternalFinancingForm, value: String) = receiver.copy(bankName = value)
    override val serializer: KSerializer<String> = String.serializer()
    override val annotations: List<Annotation> = ExternalFinancingForm.serializer().tryFindAnnotations("bankName")
    override val default: String = "" // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bankName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)approvalAmount([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)termLengthInMonths([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)interestRate([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)submittedAt([^a-zA-Z0-9]|$)
}
object ExternalFinancingForm_approvalAmount: SerializableProperty<ExternalFinancingForm, PriceInDollars?> {
    override val name: String = "approvalAmount"
    override fun get(receiver: ExternalFinancingForm): PriceInDollars? = receiver.approvalAmount
    override fun setCopy(receiver: ExternalFinancingForm, value: PriceInDollars?) = receiver.copy(approvalAmount = value)
    override val serializer: KSerializer<PriceInDollars?> = PriceInDollars.serializer().nullable2
    override val annotations: List<Annotation> = ExternalFinancingForm.serializer().tryFindAnnotations("approvalAmount")
    override val default: PriceInDollars? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bankName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)approvalAmount([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)termLengthInMonths([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)interestRate([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)submittedAt([^a-zA-Z0-9]|$)
}
object ExternalFinancingForm_termLengthInMonths: SerializableProperty<ExternalFinancingForm, Int?> {
    override val name: String = "termLengthInMonths"
    override fun get(receiver: ExternalFinancingForm): Int? = receiver.termLengthInMonths
    override fun setCopy(receiver: ExternalFinancingForm, value: Int?) = receiver.copy(termLengthInMonths = value)
    override val serializer: KSerializer<Int?> = Int.serializer().nullable2
    override val annotations: List<Annotation> = ExternalFinancingForm.serializer().tryFindAnnotations("termLengthInMonths")
    override val default: Int? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bankName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)approvalAmount([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)termLengthInMonths([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)interestRate([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)submittedAt([^a-zA-Z0-9]|$)
}
object ExternalFinancingForm_interestRate: SerializableProperty<ExternalFinancingForm, Double?> {
    override val name: String = "interestRate"
    override fun get(receiver: ExternalFinancingForm): Double? = receiver.interestRate
    override fun setCopy(receiver: ExternalFinancingForm, value: Double?) = receiver.copy(interestRate = value)
    override val serializer: KSerializer<Double?> = Double.serializer().nullable2
    override val annotations: List<Annotation> = ExternalFinancingForm.serializer().tryFindAnnotations("interestRate")
    override val default: Double? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bankName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)approvalAmount([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)termLengthInMonths([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)interestRate([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)submittedAt([^a-zA-Z0-9]|$)
}
object ExternalFinancingForm_submittedAt: SerializableProperty<ExternalFinancingForm, Instant?> {
    override val name: String = "submittedAt"
    override fun get(receiver: ExternalFinancingForm): Instant? = receiver.submittedAt
    override fun setCopy(receiver: ExternalFinancingForm, value: Instant?) = receiver.copy(submittedAt = value)
    override val serializer: KSerializer<Instant?> = ContextualSerializer(Instant::class).nullable2
    override val annotations: List<Annotation> = ExternalFinancingForm.serializer().tryFindAnnotations("submittedAt")
    override val default: Instant? = null // ([^a-zA-Z0-9]|^)_id([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)user([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)bankName([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)approvalAmount([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)termLengthInMonths([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)interestRate([^a-zA-Z0-9]|$), ([^a-zA-Z0-9]|^)submittedAt([^a-zA-Z0-9]|$)
}
